/*
■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
*/



/* Global Component */

// SNS Share
import snsShare  from '@/components/contents/sns_share'
Vue.component(snsShare.name, snsShare)

// 2020년 수상내역
import award2020  from '@/components/contents/award2020'
Vue.component(award2020.name, award2020)

// 업계 no.1 피플라이프 보험컨설팅
import bohumConsulting  from '@/components/contents/bohumConsulting'
Vue.component(bohumConsulting.name, bohumConsulting)

// 전문가 네트워크
import networkTab  from '@/components/contents/networkTab'
Vue.component(networkTab.name, networkTab)

// 컨설팅 플로우
import roadMap  from '@/components/contents/roadMap'
Vue.component(roadMap.name, roadMap)



/*
■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
*/



/* Router */
import Vue from 'vue';
import Router from 'vue-router';

/* MAIN */
import main from '@/views/main';

/* 피플라이프 */
import company from '@/views/intro/company';
import history from '@/views/intro/history';
import network from '@/views/intro/network';
import branch from '@/views/intro/branch';
import social from '@/views/intro/social';
import notify from '@/views/intro/notify';
import finance from '@/views/intro/finance';

/* 영업채널 */
import corporation from '@/views/sales/corporation';
import fa from '@/views/sales/fa';
import efa from '@/views/sales/efa';
import tfa from '@/views/sales/tfa';
import otc from '@/views/sales/otc';

/* 브랜드 */
import bohum from '@/views/brand/bohum';
import ceo from '@/views/brand/ceo';

/* 홍보센터 */
import news from '@/views/promotion/news';
import inside from '@/views/promotion/inside';
import webzine from '@/views/promotion/webzine';
import promotion from '@/views/promotion/promotion';

/* 인재채용 */
import recruitInfo from '@/views/recruit/recruitInfo';
import recruitCorp from '@/views/recruit/recruitCorp';
import recruitFa from '@/views/recruit/recruitFa';
import recruitBohum from '@/views/recruit/recruitBohum';
import recruitEfa from '@/views/recruit/recruitEfa';
import recruitComplete from '@/views/recruit/recruitComplete';

/* 게시판 */
import boardDetail from '@/views/promotion/news_detail'; //삭제예정
import boardView from '@/views/board/view';

/* COMMON */
import privacy from '@/views/etc/privacy';

/* 상담신청완료 */ 
import corpComplete from '@/views/etc/corpComplete';
import indiComplete from '@/views/etc/indiComplete';

/* CEO인사말 */ 
import newCompany from '@/views/etc/newCompany';

/* 행사일정안내 */
import invitation from '@/views/etc/invitation';

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {/* MAIN */
            path: '/',
            name: main,
            component: main
        },{
            path: '/index.html',
            name: main,
            component: main
        },{/* 피플라이프 */
            path: '/intro/company',
            name: company,
            component: company
        },{
            path: '/intro/history',
            name: history,
            component: history
        },{
            path: '/intro/network',
            name: network,
            component: network
        },{
            path: '/intro/branch',
            name: branch,
            component: branch
        },{
            path: '/intro/social',
            name: social,
            component: social
        },{
            path: '/intro/notify',
            name: notify,
            component: notify
        },{
            path: '/intro/finance',
            name: finance,
            component: finance
        },{/* 영업채널 */
            path: '/sales/corporation',
            name: corporation,
            component: corporation
        },{
            path: '/sales/fa',
            name: fa,
            component: fa
        },{
            path: '/sales/otc',
            name: otc,
            component: otc
        },{
            path: '/sales/efa',
            name: efa,
            component: efa
        },{
            path: '/sales/tfa',
            name: tfa,
            component: tfa
        },{/* 브랜드 */
            path: '/brand/bohum',
            name: bohum,
            component: bohum
        },{
            path: '/brand/ceo',
            name: ceo,
            component: ceo
        },{/* 홍보센터 */
            path: '/promotion/news',
            name: news,
            component: news
        },{
            path: '/promotion/inside',
            name: inside,
            component: inside
        },{
            path: '/promotion/webzine',
            name: webzine,
            component: webzine
        },{
            path: '/promotion/promotion',
            name: promotion,
            component: promotion
        },{/* 인재채용 */
            path: '/recruit/recruitInfo',
            name: recruitInfo,
            component: recruitInfo
        },{
            path: '/recruit/recruitCorp',
            name: recruitCorp,
            component: recruitCorp
        },{
            path: '/recruit/recruitFa',
            name: recruitFa,
            component: recruitFa
        },{
            path: '/recruit/recruitBohum',
            name: recruitBohum,
            component: recruitBohum
        },{
            path: '/recruit/recruitEfa',
            name: recruitEfa,
            component: recruitEfa
        },{
            path: '/recruit/recruitComplete',
            name: recruitComplete,
            component: recruitComplete
        },{/* 게시판 */
            path: '/promotion/news_detail', //삭제예정
            name: boardDetail,
            component: boardDetail
        },{
            path: '/board/view',
            name: boardView,
            component: boardView
        },{/* COMMON */
            path: '/privacy',
            name: privacy,
            component: privacy
        },{/* 상담완료 */ 
            path: '/corpComplete',
            name: corpComplete,
            component: corpComplete
        },{
            path: '/indiComplete',
            name: indiComplete,
            component: indiComplete
        },{/* CEO인사말 */ 
            path: '/newcompany',
            name: newCompany,
            component: newCompany
        },{/* 24년도 푸쿠옥 행사 일정 안내 */
            path: '/24_invitation',
            name: invitation,
            component: invitation
        },
    ]
})

export default router