<template>
    <section id="award2020" class="section">
        <div class="step1"></div>
        <div class="step2 isAppear90">
            <span>2020 고객이신뢰하는브랜드 <strong>대상</strong><br>(주최:한경비즈니스)</span>
            <img :src="imgPath+'awards_medal02.png'" alt="메달">
        </div>
        <div class="step3 isAppear90">
            <span>2019 한국고객만족도 <strong>1위</strong><br>(주최:한경비즈니스)</span>
            <img :src="imgPath+'awards_medal03.png'" alt="메달">
        </div>
        <div class="step4 isAppear90">
            <span>2019 고객만족브랜드 <strong>대상</strong><br>(주최:주간동아)</span>
            <img :src="imgPath+'awards_medal04.png'" alt="메달">
        </div>
    </section>
</template>



<script>
export default {
    name: 'award2020',
    data() {
        return {
            imgPath: '/img/common/contents/award2020/'
        }
    }
}
</script>



<style lang="scss" scoped>
    $imgPath : "/img/common/contents/award2020/";

    #award2020 {
        color:#fff;
        .step1 {
            position:relative; width:100%; height:105vw; padding-top:10.8vw; text-align: center; background: url(#{$imgPath}bg_awards01.jpg) no-repeat left top; background-size: 100%;
            h3{
                position: relative; margin:0; font-size:6.4vw;
                .star{
                    position:absolute; top: -7.5vw; right: 19vw;
                    img{
                        display: inline-block; width:4.9333vw;
                    }
                }
            }
            .description{
                margin-top:5.6vw; font-size:4vw; line-height: 1.8em;
            }
            span{
                position:absolute; left:5.3333vw; bottom:5.3333vw; text-align: left; font-size:3.2vw;
            }
            .img{
                position:absolute; right:0; bottom:0; width:62.6666vw; opacity: 0; transform: translateX(100%); transition: all .3s .3s linear;
            }
        }
        .step2, .step3, .step4 {
            display:flex;justify-content:center;align-items:center;position:relative; width:100%; margin-top: 1px; text-align:center; background-repeat: no-repeat; background-position: left top; background-size: 100%;
            span{
                display:block;font-size:3.2vw;line-height:1.5em;
                strong{
                    font-size:4vw;
                }
            }
            img{
                position:absolute; top:0; opacity: 0; transform: translateX(100%); transition: all .6s ease-in-out;
            }
            &.appear{                
                img{
                    opacity: 1; transform: translateX(0);
                }
            }
        }
        .step2{
            height:23.8666vw; line-height:23.8666vw; background-image: url(#{$imgPath}bg_awards02.jpg);
            img{
                left:0; width:20.1333vw; transform: translateX(-100%);
            }
        }
        .step3{
            height:24vw; line-height:24vw; background-image: url(#{$imgPath}bg_awards03.jpg);
            img{
                right:0; width:24.1333vw;
            }
        }
        .step4{
            height:23.8666vw; line-height:23.8666vw; background-image: url(#{$imgPath}bg_awards04.jpg);
            img{
                left:0; width:20.2666vw; transform: translateX(-100%);
            }
        }
    }
</style>