<template>
<!-- main -->
<main id="container" class="newScrollAction" data-name="tfa">
    <h1 class="screen_out">TM영업(TFA)</h1>

    <section class="visual">
        <div class="txt_box">
            <em><img src="/img/sales/tfa/tfa_logo.png" alt="TFA"></em>
            <p>고객에게 딱 맞는 상품을 추천!</p>
            <strong>실시간 보험 비교 서비스</strong>
        </div>
    </section>
    <section class="information">
        <h2 class="title"><img src="/img/sales/tfa/tfa_title.png" alt="tFA"></h2>
        <div class="exp">
            <div>
                <p class="img"><img src="/img/sales/tfa/pic_tfa_lady.jpg" alt=""></p>
                <p class="tit">
                    비교, 설계부터 관리까지!<br>
                    고객맞춤 보험 서비스<br>
                    <strong>「  TFA  」</strong>
                </p>
                <p class="txt">
                    24시간 실시간으로 언제나, 어디서나!<br>
                    시간과 장소에 구애 없이 클릭 한 번으로 <br>
                    암, 치매, 치아 같은 건강 문제부터<br>
                    운전자, 주택화재까지 다양한 리스크의<br>
                    맞춤형 상품의 월 보험료를 확인할 수 있습니다.
                    <br><br>
                    고객의 편의성을 최우선으로 생각하여 <br>
                    고객 편의 맞춤형 생활 보장 안심케어를 제공합니다. 
                </p>
            </div>
        </div>
    </section>
    <section class="service">
        <h3 class="title">피플라이프 TFA 차별화 서비스</h3>
        <div class="step">
            <ol>
                <li>
                    <img src="/img/sales/tfa/pic_tfa_step01.jpg" alt="">
                    <div class="box">
                        <strong>「 비대면 텔레마케팅 서비스! 」</strong>
                        <p>
                            날짜, 장소, 시간에 구애 없이! <br>
                            언제나, 어디서나! 고객님이 원하는 시간에 맞춰 <br>
                            보험에 관한 궁금증을 완벽하게 해결할 수 <br>
                            있습니다.
                        </p>
                    </div>
                </li>
                <li>
                    <img src="/img/sales/tfa/pic_tfa_step02.jpg" alt="">
                    <div class="box">
                        <strong>「 실시간 보험 비교 서비스! 」</strong>
                        <p>
                            상품을 나이, 성별, 필요한 보장 등을 <br>
                            고려한 보험료 계산 시스템을 통해 <br>
                            고객님의 예상 월 보험료를 24시간 실시간으로 <br>
                            확인할 수 있습니다.
                        </p>
                    </div>
                </li>
                <li>
                    <img src="/img/sales/tfa/pic_tfa_step03.jpg" alt="">
                    <div class="box">
                        <strong>「 고객 만족형 상담 진행! 」</strong>
                        <p>
                            기존 보험 점검, 리모델링을 위한 상담 비용 ZERO! <br>
                            보험 전문 상담사가 보험을 잘 가입할 수 있게 <br>
                            도와드립니다. 
                        </p>
                    </div>
                </li>
                <li>
                    <img src="/img/sales/tfa/pic_tfa_step04.jpg" alt="">
                    <div class="box">
                        <strong>「 가입 후 평생 관리 시스템! 」</strong>
                        <p>
                            보험 전문가가 보험 점검부터 가입 후 관리까지!  <br>
                            가입 이후에도 보험에 대해 정보 변경이나 보험금  <br>
                            청구, 라이프 플랜에 따른 보장의 재검토 필요시 <br>
                            고객님의 보험을 평생 책임집니다.
                        </p>
                    </div>
                </li>
            </ol>
        </div>
    </section>
    <section class="process">
        <h3 class="title">고객 맞춤형 컨설팅 프로세스</h3>
        <div class="step_contens">
            <div class="step1">
                <i></i>
                <strong><span>STEP 1.</span> 보험료 계산 &amp; 상품비교</strong>
                <p>
                    24시간 실시간으로 상품을<br>
                    고객 나이, 성별, 필요한 보장 등을 분석하여<br>
                    맞춤 설계를  진행합니다.
                </p>
                <img src="/img/sales/tfa/pic_tfa_step1.jpg" alt="">
            </div>
            <div class="step2">
                <i></i>
                <strong><span>STEP 2.</span> 보험점검 상담진행</strong>
                <p>
                    보험 전문가가 고객님의 정보 및 선택 상품을 <br>
                    바탕으로 무료 전화상담을 진행합니다.<br>
                    궁금한 점은 무엇이든 물어보세요!
                </p>
                <img src="/img/sales/tfa/pic_tfa_step2.jpg" alt="">
            </div>
            <div class="step3">
                <i></i>
                <strong><span>STEP 3.</span> 가입 후 평생관리</strong>
                <p>
                    가입 이후에도 가입 보험에 대해 정보 변경이나 <br>
                    보험금 청구, 라이프 플랜에 따라 <br>
                    보장의 재검토 등 필요시 관리해 드려요.
                </p>
                <img src="/img/sales/tfa/pic_tfa_step3.jpg" alt="">
            </div>
        </div>
    </section>
    <section class="prepare">
        <h3 class="title">라이프 리스크를 간편하게 대비</h3>
        <ul class="list">
            <li>
                <i>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" class="icon_line">
                        <g>
                            <path class="st0" d="M51.7,21.6l-0.2-0.9l-0.9-0.1c0,0-2.2-0.3-3.4-0.5c-0.5-1.7-1.1-3.3-2-4.8c0.5-0.6,1.1-1.5,2.1-2.8l0.5-0.7
                                L47.3,11c-0.9-1.2-1.8-2.3-2.9-3.4c-1-1.1-2.2-2-3.4-2.9l-0.7-0.5l-0.7,0.5c0,0-1.8,1.3-2.8,2.1c-1.5-0.8-3.1-1.5-4.8-2l-0.6-4.3
                                l-0.9-0.2c-2.9-0.5-6-0.5-8.9,0l-0.9,0.2l-0.1,0.9c0,0-0.3,2.2-0.5,3.4c-1.7,0.5-3.3,1.2-4.8,2c-0.6-0.5-1.5-1.2-2.8-2.1l-0.7-0.5
                                L11,4.7C9.8,5.6,8.6,6.5,7.6,7.6c-1.1,1.1-2,2.2-2.9,3.4l-0.5,0.7l2.6,3.5c-0.9,1.5-1.5,3.1-2,4.8l-4.3,0.6l-0.1,0.9
                                C0.1,23-0.1,24.5-0.1,26c0,1.5,0.1,3,0.4,4.4l0.1,0.9l0.9,0.1c0,0,2.2,0.3,3.4,0.5c0.5,1.7,1.1,3.3,2,4.8c-0.8,1-2.1,2.8-2.1,2.8
                                l-0.5,0.7L4.7,41c0.9,1.2,1.8,2.3,2.9,3.4c1,1.1,2.2,2,3.4,2.9l0.7,0.5l0.7-0.5c0,0,1.8-1.3,2.8-2.1c1.5,0.9,3.1,1.5,4.8,2l0.6,4.3
                                l0.9,0.1c1.5,0.3,3,0.4,4.4,0.4s3-0.1,4.4-0.4l0.9-0.1l0.1-0.9c0,0,0.3-2.2,0.5-3.4c1.7-0.5,3.3-1.1,4.8-2l3.5,2.6l0.7-0.5
                                c1.2-0.9,2.4-1.8,3.4-2.9c1.1-1.1,2-2.2,2.9-3.4l0.5-0.7l-0.5-0.7c0,0-1.3-1.8-2.1-2.8c0.9-1.5,1.5-3.1,2-4.8l4.3-0.6l0.2-0.9
                                C51.9,29,52,27.5,52,26C52,24.5,51.9,23,51.7,21.6z M49.3,29.1l-1.2,0.2c-2.7,0.4-3.2,0.5-3.2,1.4c-0.5,1.9-1.2,3.7-2.2,5.4
                                c-0.6,0.6-0.5,0.8,2,4.2c-0.6,0.8-1.3,1.6-2.1,2.3s-1.5,1.4-2.4,2.1l-1-0.7c-2.2-1.6-2.6-1.9-3.3-1.3c-1.7,1-3.5,1.7-5.4,2.2
                                c-0.9,0-1,0.5-1.4,3.2l-0.2,1.2c-2.1,0.3-4.2,0.3-6.2,0l-0.2-1.2c-0.4-2.7-0.5-3.2-1.4-3.2c-1.9-0.5-3.7-1.2-5.4-2.2
                                c-0.6-0.6-0.9-0.5-4.2,2c-0.8-0.6-1.6-1.3-2.4-2.1c-0.7-0.7-1.4-1.5-2.1-2.3c2.5-3.4,2.6-3.5,2-4.2c-1-1.7-1.8-3.5-2.3-5.5
                                c0-0.8-0.2-0.9-4.4-1.5c-0.1-1-0.2-2.1-0.2-3.1c0-1,0.1-2.1,0.2-3.1l4.2-0.6L7,21.4c0.5-2,1.3-3.9,2.3-5.7l0.4-0.7l-2.5-3.4
                                c0.6-0.8,1.3-1.6,2.1-2.3s1.5-1.4,2.4-2.1l3.4,2.5l0.7-0.4c1.7-1.1,3.5-1.8,5.5-2.3c0.9,0,1-0.5,1.4-3.2l0.2-1.2
                                c2-0.3,4.2-0.3,6.2,0l0.2,1.2c0.4,2.7,0.5,3.2,1.4,3.2c1.9,0.5,3.7,1.2,5.3,2.2c0.7,0.7,1.1,0.4,3.3-1.3l1-0.7
                                c0.8,0.6,1.6,1.3,2.4,2.1c0.7,0.7,1.4,1.5,2.1,2.3c-0.7,1-2,2.6-2,2.7l-0.5,0.7l0.5,0.7c1.1,1.7,1.8,3.6,2.3,5.5
                                c0,0.8,0.2,0.9,4.4,1.5c0.1,1,0.2,2.1,0.2,3.1C49.5,27.1,49.5,28.1,49.3,29.1z"/>
                            <rect x="20.9" y="24.8" class="st0" width="17.5" height="2.5"/>
                            <rect x="13.6" y="24.8" class="st0" width="3.6" height="2.5"/>
                            <rect x="20.9" y="18.2" class="st0" width="17.5" height="2.5"/>
                            <rect x="13.6" y="18.2" class="st0" width="3.6" height="2.5"/>
                            <rect x="20.9" y="31.3" class="st0" width="17.5" height="2.5"/>
                            <rect x="13.6" y="31.3" class="st0" width="3.6" height="2.5"/>
                        </g>
                    </svg>
                </i>
                <strong>생활보장 안심케어</strong>
                <span>
                    평균 경력<br>
                    10년 이상의<br>
                    전문TFA가 설계
                </span>
            </li>
            <li>
                <i>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" class="icon_line">
                        <g>
                            <path class="st0" d="M16.9,28.3H0v9.5h16.9V28.3z M14.4,35.3H2.5v-4.5h11.9V35.3z"/>
                            <path class="st0" d="M35,42.6H17.1V46h-4v2.5h4V52H35v-3.5h4V46h-4V42.6z M32.5,49.5H19.6V45h12.9V49.5z"/>
                            <path class="st0" d="M35.1,28.3v9.5H52v-9.5H35.1z M49.5,35.3H37.6v-4.5h11.9V35.3z"/>
                            <path class="st0" d="M16,23.6c2.2,0,4-1.5,4.5-3.5h4.1v-2.5h-4.1c-0.5-2-2.4-3.5-4.5-3.5c-2.6,0-4.7,2.1-4.7,4.7
                                C11.3,21.5,13.4,23.6,16,23.6z M16,16.7c1.2,0,2.2,1,2.2,2.2c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2C13.8,17.7,14.8,16.7,16,16.7z"/>
                            <path class="st0" d="M6.9,42.6c-2.6,0-4.7,2.1-4.7,4.7c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7S9.5,42.6,6.9,42.6z M6.9,49.5
                                c-1.2,0-2.2-1-2.2-2.2s1-2.2,2.2-2.2s2.2,1,2.2,2.2S8.1,49.5,6.9,49.5z"/>
                            <path class="st0" d="M30,12.5h2.5v-3H35V0H17.1v9.5H30L30,12.5L30,12.5z M19.5,2.5h12.9v4.5H19.5V2.5z"/>
                            <path class="st0" d="M45.1,42.6c-2.6,0-4.7,2.1-4.7,4.7c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7S47.8,42.6,45.1,42.6z
                                    M45.1,49.5c-1.2,0-2.2-1-2.2-2.2s1-2.2,2.2-2.2s2.2,1,2.2,2.2S46.4,49.5,45.1,49.5z"/>
                            <path class="st0" d="M40.5,26.5v-2.9h4v-9.5H26.6v9.5H38v2.9H40.5z M29.1,16.7H42v4.5H29.1V16.7z"/>
                            <path class="st0" d="M27.3,40.7v-3.1c1.6-0.4,2.9-1.7,3.3-3.3h2.9v-2.5h-2.9c-0.5-2-2.4-3.5-4.6-3.5s-4,1.5-4.5,3.5h-2.9v2.5h2.9
                                c0.4,1.6,1.7,2.9,3.3,3.3v3.1H27.3z M23.8,33.1c0-1.2,1-2.2,2.2-2.2s2.2,1,2.2,2.2s-1,2.2-2.2,2.2S23.8,34.3,23.8,33.1z"/>
                        </g>
                    </svg>
                </i>
                <strong>상품분석 알고리즘</strong>
                <span>
                    T-System을 통한<br>
                    고객 정보 암호화 및<br>
                    최적 보장분석프로그램을<br>
                    통한 상품비교 분석
                </span>
            </li>
            <li>
                <i>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 50" class="icon_line">
                        <g>
                            <polygon class="st0" points="59.5,41.7 59.5,34.9 57,34.9 57,41.7 37.4,41.7 37.4,44.2 61.5,44.2 61.5,47.3 2.5,47.3 2.5,44.2 
                                26.6,44.2 26.6,41.7 7,41.7 7,7.2 37.3,7.2 37.3,4.7 4.5,4.7 4.5,41.7 0,41.7 0,49.8 64,49.8 64,41.7 	"/>
                            <path class="st0" d="M30,31.6h8.6V14.4H30V31.6z M32.5,16.9H36v12.2h-3.6V16.9z"/>
                            <path class="st0" d="M49.8,7.5h-8.6v24h8.6V7.5z M47.3,29.1h-3.6V10h3.6V29.1z"/>
                            <path class="st0" d="M61,0.2h-8.6v31.4H61V0.2z M58.5,29.1h-3.6V2.7h3.6V29.1z"/>
                        </g>
                    </svg>
                </i>
                <strong>고객 맞춤 소통</strong>
                <span>
                    전화, 이메일, 메시지,<br>
                    카카오톡 외<br>
                    고객 소통 수단 활용
                </span>
            </li>
            <li>
                <i>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59 53" class="icon_line">
                        <g>
                            <path class="st0" d="M16.6,26.2h2.5c0-4.2,3.4-7.7,7.7-7.7h2.7c0,0,0,0,0.1,0c0,0,0,0,0.1,0h2.7c4.2,0,7.7,3.4,7.7,7.7h2.5
                                c0-4.6-3-8.4-7.2-9.7c1.9-1.5,3.1-3.8,3.1-6.2V8.8c0-4.8-3.9-8.8-8.8-8.8c-4.8,0-8.8,3.9-8.8,8.8v1.4c0,2.5,1.2,4.8,3.1,6.2
                                C19.6,17.7,16.6,21.6,16.6,26.2z M23.2,8.8c0-3.5,2.8-6.3,6.3-6.3c3.5,0,6.3,2.8,6.3,6.3v1.4c0,2.3-1.4,4.3-3.6,5.1L31,15.7
                                c-0.5,0.2-1,0.2-1.4,0.3h-0.1c-0.5,0-1-0.1-1.4-0.3l-1.2-0.4c-2.1-0.8-3.6-2.8-3.6-5.1V8.8z"/>
                            <path class="st0" d="M53.7,22.7c1.4-1.2,2.3-3,2.3-4.9v-1.1c0-3.9-3.2-7.1-7.1-7.1s-7.1,3.2-7.1,7.1v1.1c0,2.7,1.7,5.1,4.3,6.1
                                l1,0.3c0.6,0.2,1.3,0.3,1.9,0.3c0,0,0,0,0,0h1.6c3.3,0,6,2.7,6,6v13.2h-12v2.5h14.5V30.6C58.9,27,56.8,24,53.7,22.7z M47.7,21.9
                                l-0.9-0.3c-1.6-0.6-2.6-2.1-2.6-3.7v-1.1c0-2.6,2.1-4.6,4.6-4.6s4.6,2.1,4.6,4.6v1.1c0,1.7-1,3.1-2.6,3.7l-0.9,0.3
                                c-0.3,0.1-0.7,0.2-1,0.2h0l0,0C48.4,22.1,48.1,22,47.7,21.9z"/>
                            <path class="st0" d="M2.6,30.6c0-3.3,2.7-6,6-6h1.6c0,0,0,0,0,0c0.6,0,1.3-0.1,1.9-0.3l0.9-0.3c2.5-0.9,4.3-3.3,4.3-6.1v-1.1
                                c0-3.9-3.2-7.1-7.1-7.1s-7.1,3.2-7.1,7.1v1.1c0,1.9,0.9,3.7,2.3,4.9C2.2,24,0.1,27,0.1,30.6v15.7h14.5v-2.5h-12L2.6,30.6L2.6,30.6z
                                    M5.6,16.8c0-2.6,2.1-4.6,4.6-4.6s4.6,2.1,4.6,4.6v1.1c0,1.7-1,3.1-2.6,3.7l-0.9,0.3c-0.3,0.1-0.7,0.2-1.1,0.2l0,0h0
                                c-0.3,0-0.7-0.1-1-0.2l-0.9-0.3c-1.6-0.6-2.6-2.1-2.6-3.7L5.6,16.8L5.6,16.8z"/>
                            <path class="st0" d="M41.4,39h3.4v-2.5h-3.4c-0.6-5.6-5-10-10.6-10.6v-3.4h-2.5v3.4c-5.6,0.6-10,5-10.6,10.6h-3.4V39h3.4
                                c0.6,5.6,5,10,10.6,10.6V53h2.5v-3.4C36.3,49,40.8,44.6,41.4,39z M29.5,47.2c-5.2,0-9.4-4.2-9.4-9.4c0-5.2,4.2-9.4,9.4-9.4
                                s9.4,4.2,9.4,9.4C38.9,43,34.7,47.2,29.5,47.2z"/>
                            <path class="st0" d="M29.5,31.8c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6s6-2.7,6-6C35.5,34.5,32.8,31.8,29.5,31.8z M29.5,41.2
                                c-1.9,0-3.5-1.6-3.5-3.5s1.6-3.5,3.5-3.5s3.5,1.6,3.5,3.5C33,39.7,31.4,41.2,29.5,41.2z"/>
                        </g>
                    </svg>
                </i>
                <strong>LIFE RISK 대비</strong>
                <span>
                    건강, 치매, 운전자 보험,<br>
                    주택화재 등<br>
                    다양한 생활 리스크 대비
                </span>
            </li>
        </ul>
    </section>

    <!-- 제휴사 -->
    <section id="alliance">
        <h3 class="title">피플라이프 상품 제휴사</h3>
        <div class="life">
            <h4>생명보험사 제휴</h4>
            <div class="slide">
                <div class="swiper-container">
                    <ul class="swiper-wrapper">
                        <li class="swiper-slide"><img src="" alt=""></li>
                    </ul>
                </div>
                <button type="button" class="prev">이전</button>
                <button type="button" class="next">다음</button>
            </div>
            <p>
                주요 보험사 상품 판매 업무 제휴를<br>
                맺어 고객중심 맞춤형 상품을 제안합니다.
            </p>
        </div>
        <div class="damage">
            <h4>손해보험사 제휴</h4>
            <div class="slide">
                <div class="swiper-container">
                    <ul class="swiper-wrapper">
                        <li class="swiper-slide"><img src="" alt=""></li>
                    </ul>
                </div>
                <button type="button" class="prev">이전</button>
                <button type="button" class="next">다음</button>
            </div>
            <p>
                주요 보험사 상품 판매 업무 제휴를<br>
                맺어 화재보험 전문가를 통한 종합 관리 및<br>
                손해보험사 상품 비교를 통해 효율적인 방법을<br>
                제안합니다.
            </p>
        </div>
    </section>
    <!-- //제휴사 -->

    <section class="realtime">
        <h3 class="title">
            실시간 보험료 비교로<br>
            슬기로운 보험 생활
        </h3>
        <p class="description">
            보험 상품을 언제, 어디서나! <br>
            24시간, 365일! 실시간 보험료를 확인하세요
        </p>
    </section>
</main>
<!-- /main -->
</template>



<script>
export default {}
</script>



<style lang="scss" scoped>
    @import '@/assets/scss/sales/tfa.scss';
</style>
