<template>
    <div id="wrap" data-homepage="true" data-page-type="corp" data-target-device="mo" data-grid-width="auto" data-sheet-name="피플라이프MO" style="visibility:hidden; opacity:0">
        <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
        <Header />
        <router-view/>
        <Footer />
        <Clause />
    </div>
</template>

<script>

import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";
import Clause from "@/components/layout/Clause.vue";

export default { 
    name: 'wrap',
    components: {
        Header,
        Footer,
        Clause,
    },
    mounted() {},  
}    
</script>

