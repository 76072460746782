<template>
<!-- main -->
<main id="container" class="newScrollAction" data-name="news">
    <h1 class="screen_out">뉴스</h1>

    <section class="news_contents">
        <h3 class="title">
            뉴스
            <em>피플라이프에서 전하는 경영 관련 보도자료입니다.</em>
        </h3>
        <div class="visual">
            <a href="" class="thumnail"><img src="" alt=""></a>
            <div class="info">
                <i></i>
                <a href="" class="clamp3"></a>
                <span></span>
                <a href="" class="link">더보기</a>
            </div>
        </div>
        <div class="news_list">
            <div class="news_menu">
                <ul>
                    <li><button type="button" data-name="전체">전체</button></li>
                    <li><button type="button" data-name="소식">소식</button></li>
                    <li><button type="button" data-name="서비스">서비스</button></li>
                    <li><button type="button" data-name="보험클리닉">보험클리닉</button></li>
                    <li><button type="button" data-name="CEO클리닉">CEO클리닉</button></li>
                    <li><button type="button" data-name="수상">수상</button></li>
                    <li><button type="button" data-name="기타">기타</button></li>
                </ul>
            </div>
            <div class="news_menu_contents">
                <ul>
                    <li>
                        <img src="" alt="">
                        <a href="" class="info">
                            <i></i>
                            <strong class="clamp3"> </strong>
                            <span></span>
                            <button type="button">더보기</button>
                        </a>
                    </li>
                </ul>
                <button type="button" class="more">
                    <i></i>
                    더보기
                </button>
            </div>
        </div>
    </section>
</main>
<!-- /main -->
</template>



<script>
    export default {}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/promotion/news.scss';
</style>
