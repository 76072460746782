<template>
<div id="popwrap">
        


<!-- 
    #privacy : 개인정보처리방침
    #terms : 이용약관
    #credit : 신용정보활용체제공시
    #external_modal : 외부감사인 선임공고
    #submit_recruit_modal : 입사지원 레이어
    #modal_intro_ads : TVC 팝업
    #brand_pop : 브랜드 팝업
    #member_pop : 네트워크 팝업
    #collection_pop : 개인정보 수집이용 제공에 관한 동의
    #recruitInfo_pop : 법인채용 팝업
    #stockholderstime_modal : 주주명부폐쇄 공고
    #shareholder_modal : 구주권 제출 및 채권자 이의제출 공고
    #shareholder_guide_modal : 주주 보호 및 조치사항 안내
 -->

<!--
■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
-->



<!-- 네트워크 팝업 -->
<section id="member_pop" style="display:none;">
    <div class="photo">
        <img src="" alt="프로필">
        <button type="button" class="member_prev">이전</button>
        <button type="button" class="member_next">다음</button>
    </div>
    <div class="member_description">
        <p class="name"><strong></strong><span></span></p>
        <div class="major_career">
            <strong>주요경력</strong>
            <ul>
                <li></li>
            </ul>
        </div>
    </div>
    <button type="button" class="member_close" data-overlay="#member_pop">닫기</button>
</section>
<!-- //네트워크 팝업 -->



<!--
■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
-->



</div>
</template>



<style lang="scss">
    #member_pop{
        display: none;
        z-index: 50;
        overflow-y: auto;
        position: fixed;
        top:14.184vw;
        left:0;
        width:100%;
        height:calc(100% - 14.184vw);
        background-color: #fff;
        &::before,
        &::after{
            content: '';
            z-index: 5;
            position:absolute;
            top:0;
            width:50%;
            height:1.3333vw;
        }
        &::before{
            left:0;
            background-color: #87c323;
        }
        &::after{
            right:0;
            background-color: #0087d2;
        }
        .member_close{
            overflow:hidden;
            position:absolute;
            top:6.4vw;
            right:4.9333vw;
            width:4.5333vw;
            height:4.5333vw;
            text-indent: -9999px;
            background: url(/img/intro/network/btn_close.png) no-repeat left top;
            background-size: 4.5333vw;
        }
        .photo{
            overflow: hidden;
            position:relative;
            height: 100vw;
            img{
                position: absolute;
                bottom: 0;
                left: 50%;
                overflow: hidden;
                transform: translateX(-50%);
                width: 90%;
            }
            button{
                overflow: hidden;
                position: absolute;
                top: 50%;
                width: 10.9333vw;
                height: 18.0666vw;
                margin-top: -9.7333vw;
                text-indent: -9999px;
                background-position: left top;
                background-repeat: no-repeat;
                background-size: 10.9333vw;
            }
            .member_prev{
                left:0;
                background-image: url(/img/intro/network/btn_prev.png);
            }
            .member_next{
                right:0;
                background-image: url(/img/intro/network/btn_next.png);
            }
        }
        .member_description{
            padding:7.3333vw 4.5333vw;
            background: url(/img/intro/network/bg_pen.png) no-repeat right top;
            background-size: 53.3333vw;
            .name{
                display:block;
                width:64vw;
                margin:0;
                padding-bottom:3.0666vw;
                border-bottom:0.1333vw solid #e6e6e6;
                strong{
                    display: inline-block;
                    font-family: 'iropkeBatang';
                    font-size:8vw;
                    color:#333;
                    letter-spacing: .3em;
                }
                span{
                    display: inline-block;
                    vertical-align: bottom;
                    font-size:5.6vw;
                    color:#0da8ff;
                }
            }
            .major_career{
                padding-top:6.4vw;
                strong{
                    display: block;
                    font-weight: 500;
                    font-size:4.7666vw;
                    color:#3a3a3a;
                }
                ul{
                    li{
                        margin-top: 3.2vw;
                        padding-left: 4.1333vw;
                        text-indent: -4.1333vw;
                        font-size: 4.2666vw;
                        color: #3a3a3a;
                        word-break:keep-all;
                        &::before{
                            content: '';
                            display: inline-block;
                            width:2.1333vw;
                            height:0.2666vw;
                            margin-right:2vw;
                            vertical-align: middle;
                            background-color:#3a3a3a;
                        }
                    }
                }
            }
        }
    }
</style>



<script>
    export default {}
</script>


