<template>
<!-- main -->
<main id="container">

	<!-- 비쥬얼(메인) -->
	<section id="visual" class="section_visual">
		<div class="visual_content">
			<div class="visualSlide swiper-container">
				<ul class="swiper-wrapper">
					<li class="swiper-slide" data-headline-index="0">
						<div class="headline inner">
							<h3>금융상품판매 전문 플랫폼<br>피플라이프</h3>
							<p>피플라이프의 전문화된 금융 솔루션으로<br>여러분의 일상을 보다 풍요롭게 지켜드립니다.</p>
						</div>
					</li>
					<li class="swiper-slide" data-headline-index="1">
						<div class="headline inner">
							<h3>금융업계 혁신의 아이콘,<br>피플라이프</h3>
							<p>고객내방형 보험샵 '<strong>보험클리닉</strong>'</p>
							<p>중소기업 위기 극복을 위한 리스크 관리<br>컨설팅의 대표 브랜드 '<strong>CEO 클리닉</strong>'</p>
						</div>
					</li>
					<li class="swiper-slide" data-headline-index="2">
						<div class="headline inner">
							<h3>믿을 수 있는<br>피플라이프</h3>
							<p>고객님의 성원에 감사드리며,<br>더 큰 신뢰와 믿음직한 서비스로 보답하겠습니다.</p>
							<button class="brandBtn"  onclick="LAYER('awardLayer')" type="button">자세히보기<i></i></button>
						</div>
					</li>
				</ul>
			</div>
			<div class="SlidePaging">
				<div class="number"><span class="count"></span><span> / </span><span class="total"></span></div>
				<div class="swiper-pagination clearfix"></div>
			</div>
		</div>
		<div class="visualNews swiper-container">
			<ul class="list_News swiper-wrapper">
                <li class="News swiper-slide clearfix" data-attr-top="topView" data-attr-id="/id">
					<div class="float-left">
						<div class="new_img"></div>
					</div>
					<div class="float-right">
						<div class="news_tit" data-text="/title" data-clamp="2"></div>
					</div>
                </li>
			</ul>
		</div>
	</section>
	<!-- //비쥬얼(메인) -->

	<!-- 사업소개 -->
	<section id="business" class="section">
		<h2 class="tit_section">OUR <strong>Business</strong></h2>
		<div class="swiper-container">
			<div class="swiper-wrapper">
				<div class="swiper-slide first" :style="'background-image:url('+imgPath+'business_slide_bg1.jpg?v=220916)'">
					<div class="tag"><p class="tit">법인컨설팅</p></div>
					<div class="slide-inner">
						<p class="tit">중소기업, 개인사업자를<br>위한 지속 가능한 경영 컨설팅 </p>
						<p class="desc">리스크 관리부터 컨설팅 후 사후관리까지, 중소기업 위기 극복 파트너로써 고민을 해결해 드립니다. </p>
						<div class="btn_area">
							<a class="link_detail" href="/sales/corporation">자세히보기</a>
							<a class="link_recruit" href="/recruit/recruitCorp">채용정보 보기</a>
						</div>
					</div>
				</div>
				<div class="swiper-slide second" :style="'background-image:url('+imgPath+'business_slide_bg2.jpg?v=2301271)'">
					<div class="tag"><p class="tit">개인영업(FA)</p></div>
					<div class="slide-inner">
						<p class="tit">개인고객 대상<br>금융상품 비교분석・재무설계 서비스</p>
						<p class="desc">보장분석 전문 시스템 어시스트(@ssist)를 통해 보장에 대한 유지/조정/해지를 분석하고 상품을 비교하여 포트폴리오를 제공해 드립니다.</p>
						<div class="btn_area">
							<a class="link_detail" href="/sales/fa">자세히보기</a>
							<a class="link_recruit" href="/recruit/recruitFa">채용정보 보기</a>
						</div>
					</div>
				</div>
				<div class="swiper-slide" :style="'background-image:url('+imgPath+'business_slide_bg3.jpg?v=2301271)'">
					<div class="tag"><p class="tit">보험클리닉</p></div>
					<div class="slide-inner">
						<p class="tit">전국 어디서나 오프라인 보험샵<br>원스톱 보험 비교 추천 서비스</p>
						<p class="desc">요청한 날짜, 시간에 보험샵으로 방문하여 가입한 보험을 전문가에게 분석 받고, 보험사별 상품을 비교해 최적의 솔루션을 안내해 드립니다. </p>
						<div class="btn_area">
							<a class="link_detail" href="/sales/otc">자세히보기</a>
							<a class="link_recruit" href="/recruit/recruitBohum">채용정보 보기</a>
						</div>
					</div>
				</div>
				<div class="swiper-slide" :style="'background-image:url('+imgPath+'business_slide_bg5.jpg?v=2301271)'">
					<div class="tag"><p class="tit">TM영업(TFA)</p></div>
					<div class="slide-inner">
						<p class="tit">전화상담 고객 대상 자동 보장분석<br>시스템 기반 언택트 텔레마케팅 서비스</p>
						<p class="desc">비대면 텔러마케팅 조직을 운영하여 비교, 설계부터 관리까지 전문 상담원이 전 보험사의 상품을 실시간으로 비교해 드립니다. </p>
						<div class="btn_area">
							<a class="link_detail fluid" href="/sales/tfa">자세히보기</a>
						</div>
					</div>
				</div>
			</div>
			<!-- Add Pagination -->
			<div class="swiper-pagination"></div>
		</div>
	</section>
	<!-- //사업소개 -->

	<!-- TV CF 광고 -->
	<section id="tvc" class="section">
		<h2 class="tit_section">peoplelife <strong>tvc</strong></h2>
		<div class="swiper-container">
			<div class="swiper-wrapper">
				<div class="swiper-slide">
					<button data-src="/file/video/2024_tvcf2_kakao.mp4" data-title="" data-type="localhost" type="button" title="영상재생">
						<img class="thumb" src="/file/video/2024_tvcf2_kakao.jpg" alt="스크린샷">
					</button>
					<p class="tit">TVC광고<br> &#60;카톡&#62;편</p>
				</div>
				<div class="swiper-slide">
					<button data-src="/file/video/2024_tvcf2_search.mp4" data-title="" data-type="localhost" type="button" title="영상재생">
						<img class="thumb" src="/file/video/2024_tvcf2_search.jpg" alt="스크린샷">
					</button>
					<p class="tit">TVC광고<br> &#60;검색&#62;편</p>
				</div>
				<div class="swiper-slide">
					<button data-src="/file/video/2024_tvcf2_youtube.mp4" data-title="" data-type="localhost" type="button" title="영상재생">
						<img class="thumb" src="/file/video/2024_tvcf2_youtube.jpg" alt="스크린샷">
					</button>
					<p class="tit">TVC광고<br> &#60;유튜브&#62;편</p>
				</div>
				<div class="swiper-slide">
					<button data-src="/video/tvcf/bohumclinic/2023_season1_1.mp4" data-title="" data-type="" type="button" title="영상재생">
						<img class="thumb" src="" data-images-path="/video/tvcf/bohumclinic/2023_season1_1.jpg" alt="스크린샷">
					</button>
					<p class="tit">TVC광고<br> &#60;보험고민&#62;편</p>
				</div>
				<div class="swiper-slide">
					<button data-src="/video/tvcf/bohumclinic/2023_season2_1.mp4" data-title="" data-type="" type="button" title="영상재생">
						<img class="thumb" src="/file/video/2023_season2_1.jpg?v=240503" alt="스크린샷">
					</button>
					<p class="tit">TVC광고<br> &#60;실손&#62;편</p>
				</div>
				<div class="swiper-slide">
					<button data-src="/video/tvcf/bohumclinic/2023_season3_1.mp4" data-title="" data-type="" type="button" title="영상재생">
						<img class="thumb" src="/file/video/2023_season3_1.jpg?v=240503" alt="스크린샷">
					</button>
					<p class="tit">TVC광고<br> &#60;일반&#62;편</p>
				</div>
				<div class="swiper-slide">
					<button data-src="/video/tvcf/bohumclinic/2020_season2_1.mp4" data-title="" data-type="" type="button" title="영상재생">
						<img class="thumb" src="" data-images-path="/video/tvcf/bohumclinic/2020_season2_1.jpg" alt="스크린샷">
					</button>
					<p class="tit">TVC광고<br> &#60;나이스&#62;편</p>
				</div>
				<div class="swiper-slide">
					<button data-src="/video/tvcf/bohumclinic/2020_season2_2.mp4" data-title="" data-type="" type="button" title="영상재생">
						<img class="thumb" src="" data-images-path="/video/tvcf/bohumclinic/2020_season2_2.jpg" alt="스크린샷">
					</button>
					<p class="tit">TVC광고<br> &#60;자꾸만 보고싶네&#62;편</p>
				</div>
				<div class="swiper-slide">
					<button data-src="/video/tvcf/bohumclinic/2019_season2_2.mp4" data-title="" data-type="" type="button" title="영상재생">
						<img class="thumb" src="" data-images-path="/video/tvcf/bohumclinic/2019_season2_2.jpg" alt="스크린샷">
					</button>
					<p class="tit">TVC광고<br> &#60;여기서 내려요&#62;편</p>
				</div>
				<div class="swiper-slide">
					<button data-src="/video/tvcf/bohumclinic/2019_season2_1.mp4" data-title="" data-type="" type="button" title="영상재생">
						<img class="thumb" src="" data-images-path="/video/tvcf/bohumclinic/2019_season2_1.jpg" alt="스크린샷">
					</button>
					<p class="tit">TVC광고<br> &#60;어쩌다 마주친&#62;편</p>
				</div>
				<div class="swiper-slide">
					<button data-src="/video/tvcf/ceoclinic/2019_season2.mp4" data-title="" data-type="" type="button" title="영상재생">
						<img class="thumb" src="" data-images-path="/video/tvcf/ceoclinic/2019_season2.jpg" alt="스크린샷">
					</button>
					<p class="tit">TVC광고<br> &#60;위기극복&#62;편</p>
				</div>
			</div>
		</div>
		<div class="pagination">
            <div class="count">
                <span class="num"></span> / <span class="total"></span>
            </div>
            <div class="swiper-pagination"></div>
		</div>
	</section>
	<!-- //TV CF 광고 -->

	<!-- 뉴스/매거진 -->
	<section id="about" class="section section_about marginNone">
		<h2><strong>about </strong>peoplelife</h2>
		<div class="about_webzine">
			<div class="webzineCnt clearfix">
				<div class="float-left"><a href="/promotion/webzine"><img class="webzineImg" src="/img/main/webzine/about_webzine_month_07.png" alt="피플라이프 웹진 06월호"></a></div>
				<div class="float-right">
					<div class="desc">
						<!-- <p class="tit"><i>Peoplelife webzine</i></p> -->
						<div class="daycnt">
							<p><span class="nowYear"></span>. <span class="nowMonth"></span></p>
						</div>
					</div>
					<a href="/promotion/webzine">웹진보기 <i></i></a>
				</div>
			</div>
		</div>
		<div class="about_news">
			<div class="news_swiper">
				<div class="newsWarp swiper-container">
					<ul class="news_list swiper-wrapper">
						<li class="news_item swiper-slide" style="background-image: url(/img/main/@thb-news.jpg);">
							<a href="">
								<div class="new_img" style="background-image: url(/common/public-board/image/${item.fileId3});"></div>
								<div class="new_img" style="background-image: url(/img/main/@thb-news.jpg);"></div>
								<div class="desc">
									<div class="news_category" data-text="category2"></div>
									<div class="news_tit" data-text="title" data-clamp="2"></div>
									<div class="news_date" data-text="writeDate"></div>
								</div>
							</a>
						</li>
					</ul>
				</div>
				<div class="pagination">
					<div class="count">
						<span class="num"></span> / <span class="total"></span>
					</div>
					<div class="swiper-pagination"></div>
				</div>
			</div>
			<a href="/promotion/news">뉴스보기 <i></i></a>
		</div>
	</section>
	<!-- //뉴스/매거진 -->

	<!-- 채용 -->
	<section id="recruit" class="section section_recruit marginNone">
		<div class="inner">
			<h2><strong>recruit</strong> peoplelife</h2>
		</div>
		<div class="recruit_cont">
			<div class="recruit_wrap">
				<h3>피플라이프와 함께 금융 전문가로 거듭날 <br> 인재를 기다립니다.</h3>
				<ul class="recruit_list clearfix">
					<li class="recruit_corp"><!-- data-recruit-state="deadline" -->
						<a href="/recruit/recruitCorp">
							법인컨설팅 채용
						</a>
						<i class="deadline_label"></i>
					</li>
					<li class="recruit_fa">
						<a href="/recruit/recruitFa">
							개인영업(FA) 채용
						</a>
						<i class="deadline_label"></i>
					</li>
					<li class="recruit_insurance"><!-- data-recruit-state="deadline" -->
						<a href="/recruit/recruitBohum">
							보험클리닉  채용
						</a>
						<i class="deadline_label"></i>
					</li>	
				</ul>
				<a href="/recruit/recruitInfo" class="recruitBtn">채용정보</a>
			</div>
			<img src="/img/main/new/recruit_person.png" alt="피플라이프와 함께 금융 전문가로 거듭날 인재를 기다립니다">
		</div>
	</section>
	<!-- //채용 -->

	<!-- 수상내역 -->
	<section id="award" class="section section_award marginNone">
		<div class="inner">
			<div class="star">
				<i></i>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
			<div class="tit">
				<p>고객 신뢰로 이루어낸 빛나는 수상</p>
			</div>
			<div class="medal">
				<ul class="medal_list clearfix">
					<li><img src="/img/main/new/award_new_metal01.png" alt="2024 프리미엄 브랜드대상, 대한민국 소비자 선호도 1위"></li>
					<li><img src="/img/main/new/award_new_metal02.png" alt="2021 대한민국 소비자 선호도 1위"></li>
					<li><img src="/img/main/new/award_new_metal03.png" alt="2019 고객이 직접 선택한 한국고객만족도 1위"></li>
					<li><img src="/img/main/new/award_new_metal04.png" alt="2019 대한민국 소비자 선호도 1위"></li>
					<li><img src="/img/main/new/award_new_metal05.png" alt="2020 고객이 신뢰하는 브랜드 대상"></li>
					<li><img src="/img/main/new/award_new_metal06.png" alt="2020 2년연속 한국소비자만족지수 1위"></li>
				</ul>
			</div>
		</div>
	</section>
	<!-- //수상내역 -->

</main>
<!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/main/'
        }
    },
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/main/main.scss';
</style>