<template>
<!-- main -->
<main id="container" class="newScrollAction" data-name="network">
    <h1 class="screen_out">전문가네트워크</h1>

    <section class="visual">
        <p>
            고객분들에게 딱 맞게 제안하는<br>
            분명하고 혁신적인 전략
        </p>
    </section>
    <section class="network_list">
        <p class="desc">
            피플라이프는 분야별 전문가 그룹을 통해<br>
            자산관리 및 경영 지원 분야에 필요한 모든 프로세스를<br>
            효율적으로 지원합니다.
        </p>
        <div class="network_tab_menu list1">
            <div class="tab_menu_list">
                <ul>
                    <li data-type="세무" data-id="list1" class="on"><button type="button">세무</button></li>
                    <li data-type="법무" data-id="list2"><button type="button">법무</button></li>
                    <li data-type="노무" data-id="list3"><button type="button">노무</button></li>
                    <li data-type="부동산" data-id="list4"><button type="button">부동산</button></li>
                    <li data-type="기업금융" data-id="list5"><button type="button">기업<br>금융</button></li>
                </ul>
            </div>
            <div class="tab_menu_contents">
                <div id="list1" data-type="세무" class="list_contents active">
                    <div class="sub_txt_contents">
                        <h2 class="tit">세무법인 세종TSI</h2>
                        <p class="txt">
                            세종TSI는 피플라이프와 제휴된 업체로 <br>
                            법인 및 고액자산가들에 대한 경영&자산관리 컨설팅을 <br>
                            제공하고 있는 세무법인 입니다.                            
                        </p>
                    </div>
                    <div class="sub_tab_contents">
                        <div class="sub_tab">
                            <div class="service">
                                <div>
                                    <h3 class="title">주요서비스</h3>
                                    <div class="service_tab">
                                        <ul>
                                            <li data-id="list1_1" class="on"><button type="button">기업경영 영역</button></li>
                                            <li data-id="list1_2"><button type="button">오너CEO자산관리 영역</button></li>
                                        </ul>
                                    </div>
                                    <div id="list1_1" class="service_contents active">
                                        <ul>
                                            <li>이익금 관리</li>
                                            <li>세무 관련 제도 정비</li>
                                            <li>핵심인재관리 및 보상</li>
                                            <li>모의 세무진단</li>
                                            <li>외부감사 전략 수립</li>
                                            <li>기업진단</li>
                                            <li>합병 및 분할 전략 수립</li>
                                            <li>차명 주식 회수</li>
                                            <li>가지급금, 가수금 정리</li>
                                            <li>가업승계</li>
                                            <li>개인사업 법인전환</li>
                                            <li>법인에 대한 EXIT 플랜<br>(적정 보수, 적정배당정책, 자기주식, 청산 등)</li>
                                        </ul>
                                    </div>
                                    <div id="list1_2" class="service_contents">
                                        <ul>
                                            <li>상속증여 플랜</li>
                                            <li>금융자산 플랜</li>
                                            <li>부동산관련 세금</li>
                                            <li>차명주식 회수</li>
                                            <li>가지급금, 가수금 정리</li>
                                            <li>가업승계</li>
                                            <li>개인사업법인전환</li>
                                            <li>법인에 대한 EXIT 플랜<br>(적정 보수, 적정배당정책, 자기주식, 청산 등)</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="list2" data-type="법무" class="list_contents">
                    <div class="sub_txt_contents">
                        <h2 class="tit">가이우스 법률 사무소</h2>
                        <p class="txt">
                            가이우스 법률사무소는 중소기업을 위한 법률상의 특례 제도를<br>
                            구현시키고, 상법의 회사법편에서 규율하고 있는<br>
                            회사 경영의 원칙을 중소기업에 정착시키기 위한<br>
                            자문과 소송에 특화되어 있습니다.
                        </p>
                    </div>
                    <div class="sub_tab_contents">
                        <div class="sub_tab">
                            <div class="service">
                                <div>
                                    <h3 class="title">주요서비스</h3>
                                    <div id="list2_1" class="service_contents" style="display:block;">
                                        <ul>
                                            <li>법률상 특례 제도 구현을 위한 중소기업 육성 및 지원 법률 연구</li>
                                            <li>상법의 회사법편에 따른 적법한 회사 경영에 대한 자료</li>
                                            <li>동업자나 직원의 배신으로 인한 경영 리스크 대비 방안</li>
                                            <li>기업의 Know How 산업재산권화 지원</li>
                                            <li>중소기업 경영을 위한 계약서 작성 및 계약서 검토</li>
                                            <li>중소기업의 골칫거리 차명 주식의 실명전환</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="list3" data-type="노무" class="list_contents">
                    <div class="sub_txt_contents">
                        <h2 class="tit">노무법인 익선, 서현</h2>
                        <p class="txt">
                            좋은 직원을 고용하여 유지하는 것은 중소기업 경쟁력에<br>
                            있어서 빼놓을 수 없는 영역입니다. 노무법인 익선과<br>
                            서현은 간단치 않은 중소기업의 인사와 노무 문제에<br>
                            대한 풍부한 Solution을 제공해 드립니다.
                        </p>
                    </div>
                    <div class="sub_tab_contents">
                        <div class="sub_tab">
                            <div class="service">
                                <div>
                                    <h3 class="title">주요서비스</h3>
                                    <div class="service_tab">
                                        <ul>
                                            <li data-id="list3_1" class="on"><button type="button">노무 법률 자문</button></li>
                                            <li data-id="list3_2">
                                                <button type="button"><span>급여아웃소싱 / <br>4대보험관리</span></button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div id="list3_1" class="service_contents active">
                                        <ul>
                                            <li>근로시간, 임금, 근태, 근로관계 종료, 퇴직금 등 각종 개별적 근로관계에 관한 법률 자문</li>
                                            <li>산업재해 및 산업안전에 관한 법률문제, 외국인 근로자와 관련된 문제 자문</li>
                                            <li>노조 설립, 단체협상, 쟁의와 개별 요구안에 대한 법률적 평가와 대응 등 노사문제 법률 자문</li>
                                            <li>연봉제 평가 제도, 성과급제 등, 인사제도 운영 실태에 대한 자문</li>
                                            <li>파견, 도급, 기간제 근로자와의 법률문제, 국민연금, 고용, 산재, 건강보험 업무 자문</li>
                                            <li>쟁의행위 발생 시의 대응 자문</li>
                                        </ul>
                                    </div>
                                    <div id="list3_2" class="service_contents">
                                        <ul>
                                            <li>급여체계와 개인별 급여관리, 퇴직자 관리</li>
                                            <li>산업재해 신청 및 급여 청구</li>
                                            <li>4대보험 취득 및 상실 업무, 근로계약서, 취업규칙 정비</li>
                                            <li>해고, 인사 청구, 구제신청 및 대응, 산재 고용보험, 임금체불, 체당금</li>
                                            <li>노동사건 대리 및 교육서비스</li>
                                            <li>성희롱 예방교육 등 각종 법정 필수 교육 서비스</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="list4" data-type="부동산" class="list_contents">
                    <div class="sub_txt_contents">
                        <h2 class="tit">부동산 전문</h2>
                        <p class="txt">
                            부동산과 관련된 고객의 수요를 전문가의 시각으로<br>
                            분석하고 풍부한 네트워크를 활용하여<br>
                            최적의 Solution을 제공해 드리겠습니다.
                        </p>
                    </div>
                    <div class="sub_tab_contents">
                        <div class="sub_tab">
                            <div class="service">
                                <div>
                                    <h3 class="title">주요서비스</h3>
                                    <div id="list4_1" class="service_contents" style="display:block;">
                                        <ul>
                                            <li>부동산의 매입, 매각, 개발, 리모델링과 관련된 부동산 최유효 활용</li>
                                            <li>부동산 매각 관련 적정 Value, 매각 마케팅 계획, 홍보전략 등 자문</li>
                                            <li>부동산 매매, 임대 관련 중개</li>
                                            <li>부동산 법인전환의 검토와 실행 서비스</li>
                                            <li>부동산 감정평가, 법무 등기, 개발 검토, 분양대행, 부동산 PM</li>
                                            <li>부동산 매입 관련 적정 Value, 자금계획, 소유구조 등 자문</li>
                                            <li>특수관계자 간 부동산 활용</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="list5" data-type="기업금융" class="list_contents">
                    <div class="sub_txt_contents">
                        <h2 class="tit">IPO · M&amp;A 전문 머스트윌</h2>
                        <p class="txt">
                            중소 · 벤처기업을 위한<br>
                            기업금융(Corporation Financing) 자문을<br>
                            전문으로 합니다.
                        </p>
                    </div>
                    <div class="sub_tab_contents">
                        <div class="sub_tab">
                            <div class="service">
                                <div>
                                    <h3 class="title">주요서비스</h3>
                                    <div class="service_tab">
                                        <ul>
                                            <li data-id="list5_1" class="on"><button type="button">IPO 컨설팅</button></li>
                                            <li data-id="list5_2"><button type="button">M&amp;A 컨설팅</button></li>
                                            <li data-id="list5_3"><button type="button">자금조달 컨설팅</button></li>
                                        </ul>
                                    </div>
                                    <div id="list5_1" class="service_contents active">
                                        <ul>
                                            <li>코스닥 상장 심사 통과 가능성에 대한 분석 및 미비점에 대한 해결책 제시</li>
                                            <li>사전 준비 단계에서 직면하는 이슈에 대한 해결책 및 일정을 포함하여 IPO 로드맵 작성</li>
                                            <li>주관 증권사 선정 후 상장 본 단계에서 발생하는 이슈 및 대응책에 대한 Cross-check</li>
                                        </ul>
                                    </div>
                                    <div id="list5_2" class="service_contents">
                                        <ul>
                                            <li>M&amp;A 관련 주체들인 증권회사, 회계법인 PEF, 벤처캐피탈 등 과의 협업을 통해 DEAL 성공률을 극대화</li>
                                            <li>M&amp;A 관련 협상 자문 및 계약서 등 작성 자문</li>
                                            <li>M&amp;A 관련 대상에 대한 타당성 평가 및 자문</li>
                                        </ul>
                                    </div>
                                    <div id="list5_3" class="service_contents">
                                        <ul>
                                            <li>투자유치를 위한 제반 여건 충족 여부에 대한 분석과 해결책 제시</li>
                                            <li>투자조건, 방식(RCPS, CB, BW 등) 및 자료 준비 등을 포함한 실행전략 수립</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="member_list">
                <h4 class="title">구성원</h4>
                <ul class="clearfix">
                    <li>
                        <button type="button">
                            <strong>
                                <span class="name" data-text="name">이름</span>
                                <em class="role" data-text="role"></em>
                            </strong>
                            <img src="" alt="">
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</main>
<!-- /main -->
</template>



<script>
    export default {}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/intro/network.scss';
</style>
