<template>
<!-- main -->
<main id="container" class="newScrollAction" data-name="recruitInfo">
    <h1 class="screen_out">채용정보</h1>

    <section class="visual">
        <div class="top">
            <p class="txt">
                피플라이프와 함께라면<br>
                당신의 성공신화는 잠들지 않습니다
            </p>
            <h4 class="tit">
                <strong>PEOPLELIFE</strong> Recruit
            </h4>
            <ul class="list">
                <li class="on">
                    <a href="/recruit/recruitCorp">
                        <strong>법인컨설팅 채용</strong>
                        <em>자세히 보기</em>
                    </a>
                    <span>
                        <strong>법인컨설팅 채용</strong>
                        채용공고가 마감되었습니다.
                    </span>
                </li>
                <li class="on">
                    <a href="/recruit/RecruitFa">
                        <strong>개인영업(FA) 채용</strong>
                        <em>자세히 보기</em>
                    </a>
                    <span>
                        <strong>개인영업(FA) 채용</strong>
                        채용공고가 마감되었습니다.
                    </span>
                </li>
                <li class="on">
                    <a href="/recruit/RecruitBohum">
                        <strong>보험클리닉 채용</strong>
                        <em>자세히 보기</em>
                    </a>
                    <span>
                        <strong>보험클리닉 채용</strong>
                        채용공고가 마감되었습니다.
                    </span>
                </li>
                <!-- <li class="">
                    <a href="/recruit/RecruitEfa">
                        <strong>
                            정규직 개인영업<br>
                            (EFA) 채용
                        </strong>
                        <em>자세히 보기</em>
                    </a>
                    <span>
                        <strong>
                            정규직 개인영업<br>
                            (EFA) 채용
                        </strong>
                        채용공고가 마감되었습니다.
                    </span>
                </li> -->
            </ul>
        </div>
        <div class="interview">
            <div class="swiper-container">
                <ul class="swiper-wrapper">
                    <li class="swiper-slide">
                        <div>
                            <span>
                                피플라이프에 오게 된 후 이전에는 몰랐던<br>
                                것들을 너무 많이 알게 됐어요. 이제야 고객에게 <br>
                                부족한 부분들을 속속들이 알차게 채워드릴 수 <br>
                                있게 되었고, 덕분에 하루가 24시간이 아니라 <br>
                                48시간이었으면 좋겠다고 느낄 정도로 바쁜 <br>
                                시간을 보내고 있습니다.
                            </span>
                            <em>피플라이프 FA 이O남 팀장</em>
                        </div>
                    </li>
                    <li class="swiper-slide">
                        <div>
                            <span>
                                보험에 입문했을 당시에만 해도 막연했던  <br>
                                것들이 피플라이프에서 이뤄지고 있어요.  <br>
                                직업적 안정과, 안정된 소득이 필요했던  <br>
                                저에게 정규직 보험상담 매니저는 아쉬움이  <br>
                                가득했던 부분들을 충족시켜 줄 수 있는  <br>
                                최고의 선택이었어요.  <br>
                                저의 역량과 만족도를 최대한으로 끌어주는  <br>
                                회사에서 고객에게 더 좋은 서비스를 제공할 수  <br>
                                있어서 너무 기쁩니다.
                            </span>
                            <em>피플라이프 EFA 박O아 매니저</em>
                        </div>
                    </li>
                    <li class="swiper-slide">
                        <div>
                            <span>
                                쾌적한 하고 안정적인 환경, 고객이 직접  <br>
                                찾아와 서비스를 요청한다는 점이 매력적인  <br>
                                점이라고 생각했어요. 또 보험클리닉은  <br>
                                영업이 주 목적이 아니기 때문에, 고객의  <br>
                                이야기를 경청하고 고객의 불편함을 해결하기  <br>
                                위해 노력해요. 만족감을 느끼는 고객을 보면  <br>
                                자부심을 느끼곤 합니다. 이게 제가 진정으로  <br>
                                제공하고 싶던 서비스라는 생각이 듭니다.
                            </span>
                            <em>보험클리닉 김O민 매니저</em>
                        </div>
                    </li>
                </ul>
                <div class="swiper-pagination"></div>
                <button type="button" class="prev">이전</button>
                <button type="button" class="next">다음</button>
            </div>
        </div>
    </section>
    <section class="business">
        <h3 class="title">
            <strong>Business</strong>
            Support
        </h3>
        <div class="businss_slide">
            <div class="swiper-container">
                <ul class="swiper-wrapper">
                    <li class="swiper-slide">
                        <span class="img"><img src="/img/recruit/recruitInfo/img_businss_slide01.jpg" alt=""></span>
                        <div class="info">
                            <strong class="tit">교육지원</strong>
                            <span class="txt">
                                법인을 대상으로 한 컨설팅 영업을  <br>
                                조직화하여 컨설턴트의 사업적  비전과 품격을  <br>
                                한 단계 업그레이드한 원동력은 바로 교육의  <br>
                                힘입니다.
                            </span>
                        </div>
                    </li>
                    <li class="swiper-slide">
                        <span class="img"><img src="/img/recruit/recruitInfo/img_businss_slide02.jpg" alt=""></span>
                        <div class="info">
                            <strong class="tit">광고 &middot; 홍보 지원</strong>
                            <span class="txt">
                                피플라이프는 보다 전문적이고 세련된 <br>
                                기업 브랜드를 구축하고자 유명 배우를 <br>
                                광고모델로 대대적 광고를 시행하여 기업 신뢰도 <br>
                                향상에 힘을 기울이고 있습니다.
                            </span>
                        </div>
                    </li>
                    <li class="swiper-slide">
                        <span class="img"><img src="/img/recruit/recruitInfo/img_businss_slide03.jpg" alt=""></span>
                        <div class="info">
                            <strong class="tit">상품제휴</strong>
                            <span class="txt">
                                피플라이프 주요 보험사와 상품 판매 업무 제휴를 맺어<br>
                                상품 비교를 통해 고객 중심의 맞춤형 상품을 <br>
                                제안합니다.
                            </span>
                        </div>
                    </li>
                    <li class="swiper-slide">
                        <span class="img"><img src="/img/recruit/recruitInfo/img_businss_slide04.jpg" alt=""></span>
                        <div class="info">
                            <strong class="tit">전문가 네트워크</strong>
                            <span class="txt">
                                피플라이프는 중소기업 경영지원 컨설팅 <br>
                                분야에서 뛰어난 솔루션을 보유한 전문가 그룹과 <br>
                                제휴관계를 맺고 있습니다.
                            </span>
                        </div>
                    </li>
                    <li class="swiper-slide">
                        <span class="img"><img src="/img/recruit/recruitInfo/img_businss_slide05.jpg" alt=""></span>
                        <div class="info">
                            <strong class="tit">고객세미나 지원</strong>
                            <span class="txt">
                                아마추어 골프 대회, 이슈별 고객 초청 세미나를 <br>
                                통해 기존 고객에 대한 서비스 측면 외에도 <br>
                                신규 고객 창출을 위한 뛰어난 인프라를 구축하고 <br>
                                있습니다.
                            </span>
                        </div>
                    </li>
                    <li class="swiper-slide">
                        <span class="img"><img src="/img/recruit/recruitInfo/img_businss_slide06.jpg" alt=""></span>
                        <div class="info">
                            <strong class="tit">전산시스템</strong>
                            <span class="txt">
                                자체 개발한 ERP 시스템 PINES를 통해 PC, <br>
                                Mobile에서 통합 고객 관리 시스템이 지원되며 <br>
                                지능형 보장분석 시스템으로 최적의 경쟁력을 <br>
                                지원합니다.
                            </span>
                        </div>
                    </li>
                    <li class="swiper-slide">
                        <span class="img"><img src="/img/recruit/recruitInfo/img_businss_slide07.jpg" alt=""></span>
                        <div class="info">
                            <strong class="tit">DB제공 시스템</strong>
                            <span class="txt">
                                피플라이프만의 다양한 마케팅을 통해 고객들이 <br>
                                자발적으로 찾아오는 양질의 시장을 제공함으로써 <br>
                                컨설턴트의 활동을 지원합니다.
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="counter">
                <span class="num">01</span> / <span class="total"></span>
            </div>
            <div class="swiper-pagination"></div>
        </div>
    </section>
    <!-- <section class="hirestep">
        <h3 class="title">채용절차</h3>
        <div class="step_list">
            <ul>
                <li>
                    <span><img src="/img/recruit/recruitInfo/img_hirestep01.png" alt=""></span>
                    <em>서류전형</em>
                </li>
                <li>
                    <span><img src="/img/recruit/recruitInfo/img_hirestep02.png" alt=""></span>
                    <em>실무진면접</em>
                </li>
                <li>
                    <span><img src="/img/recruit/recruitInfo/img_hirestep03.png" alt=""></span>
                    <em>임원면접</em>
                </li>
                <li>
                    <span><img src="/img/recruit/recruitInfo/img_hirestep04.png" alt=""></span>
                    <em>최종합격</em>
                </li>
            </ul>
            <p class="description">*채용 전형에 따라 절차가 달라질 수 있습니다. 자세한 내용은 채용공고를 참고 바랍니다.</p>
        </div>
    </section>
    <section class="welfare">
        <h3 class="title">복리후생</h3>
        <p class="description">
            피플라이프는 임직원의 복지 향상을 위해<br>
            다양한 복리후생 제도를 운영하고 있습니다.<br>
            <span>※ 채용직군에 따라 일부 상이할 수 있습니다.</span>
        </p>
        <ul class="list">
            <li class="point4">
                <img src="/img/recruit/recruitInfo/img_welfare01.png" alt="">
                <span>
                    4대 보험 및<br>
                    퇴직금
                </span>
            </li>
            <li>
                <img src="/img/recruit/recruitInfo/img_welfare02.png" alt="">
                <span style="top: 18.6vw">
                    연차
                    <em>
                        근로기준법에 의한<br>
                        근속기간별 추가<br>
                        휴가 제공
                    </em>
                </span>
            </li>
            <li class="point2">
                <img src="/img/recruit/recruitInfo/img_welfare03.png" alt="" style="margin-left:2.5vw">
                <span>
                    자유로운<br>
                    연차 사용 권장
                </span>
            </li>
            <li style="padding-top:9vw;">
                <img src="/img/recruit/recruitInfo/img_welfare04.png" alt="">
                <span>
                    경조사 휴가/경조금/<br>
                    화환 지급
                </span>
            </li>
            <li class="point2" style="padding-top:6.4vw;">
                <img src="/img/recruit/recruitInfo/img_welfare05.png" alt="">
                <span>
                    명절 상여금
                </span>
            </li>
            <li class="point3" style="padding-top:7.5vw;">
                <img src="/img/recruit/recruitInfo/img_welfare06.png" alt="">
                <span>
                    장학 지원금
                    <em>초,중,고,대학교</em>
                </span>
            </li>
            <li class="point2">
                <img src="/img/recruit/recruitInfo/img_welfare07.png" alt="">
                <span>
                    출산휴가
                </span>
            </li>
            <li>
                <img src="/img/recruit/recruitInfo/img_welfare08.png" alt="">
                <span>
                    육아휴직
                </span>
            </li>
            <li class="point4">
                <img src="/img/recruit/recruitInfo/img_welfare09.png" alt="">
                <span>
                    매년 Staff<br>
                    해외연수
                </span>
            </li>
            <li class="point3">
                <img src="/img/recruit/recruitInfo/img_welfare10.png" alt="">
                <span>
                    장기근속자 우대
                    <em>휴가 및 보너스</em>
                </span>
            </li>
            <li class="point4" style="padding-top:8vw;">
                <img src="/img/recruit/recruitInfo/img_welfare11.png" alt="">
                <span style="top: 18.6vw">
                    PC 오프제
                    <em>
                        근무시간 이후<br>
                        자동PC종료
                    </em>
                </span>
            </li>
            <li style="padding-top:8vw;">
                <img src="/img/recruit/recruitInfo/img_welfare12.png" alt="">
                <span>
                    분기별 워크샵
                </span>
            </li>
            <li class="point2" style="padding-top:8vw;">
                <img src="/img/recruit/recruitInfo/img_welfare13.png" alt="">
                <span>
                    연장근무 수당 지급<br>
                    또는 대체휴가
                </span>
            </li>
            <li style="padding-top:9vw;">
                <img src="/img/recruit/recruitInfo/img_welfare14.png" alt="">
                <span>
                    휴일근무 수당<br>
                    또는 대체휴가
                </span>
            </li>
            <li class="point2" style="padding-top:8vw;">
                <img src="/img/recruit/recruitInfo/img_welfare15.png" alt="">
                <span style="top: 20.5vw">
                    점심휴게시간<br>
                    12:00 ~ 13:30분<br>
                    제공
                </span>
            </li>
            <li class="point3" style="padding-top:8vw;">
                <img src="/img/recruit/recruitInfo/img_welfare16.png" alt="">
                <span>
                    임산부의 경우<br>
                    태아검진 시간 허용
                </span>
            </li>
            <li class="point2" style="padding-top:8vw;">
                <img src="/img/recruit/recruitInfo/img_welfare17.png" alt="">
                <span style="top: 20.7vw">
                    육아기 근로시간 단축<br>
                    및 가족돌봄휴직 및<br>
                    근로시간 단축
                </span>
            </li>
            <li class="point4" style="padding-top:8vw;">
                <img src="/img/recruit/recruitInfo/img_welfare18.png" alt="">
                <span style="top: 20.7vw">
                    특별휴가 제공
                    <em>
                        격려휴가, 포상휴가,<br>
                        특별휴가 등
                    </em>
                </span>
            </li>
        </ul>
    </section> -->
    <section class="support">
        <h3 class="title">피플라이프 인재지원</h3>
        <p class="description">경력개발, 교육체계, 공정한 평가를 통해<br>개인의 성장을 지원합니다.</p>
        <div class="support_slide">
            <div class="swiper-container">
                <ul class="swiper-wrapper">
                    <li class="swiper-slide">
                        <img src="/img/recruit/recruitInfo/img_support01.jpg" alt="">
                        <span>
                            <strong>공평한 기회</strong>
                            학력, 성별 등 조건에 상관없이 개인의<br>
                            능력과 자질을 존중하는 블라인드<br>
                            테스트를 통해 공정한 기회를 제공합니다.
                        </span>
                    </li>
                    <li class="swiper-slide">
                        <img src="/img/recruit/recruitInfo/img_support02.jpg" alt="">
                        <span>
                            <strong>체계적인 교육</strong>
                            근무 중인 상담 매니저에게 정기적인 교육<br>
                            및 경력개발을 적극 지원하며 업계의<br>
                            핵심인재로 육성합니다.
                        </span>
                    </li>
                    <li class="swiper-slide">
                        <img src="/img/recruit/recruitInfo/img_support03.jpg" alt="">
                        <span>
                            <strong>성과에 따른 보상 제도</strong>
                            성과에 따른 인센티브가 제공되며, 조직<br>
                            기여도 등 기타 평가를 통해 진급 등<br>
                            체계적인 보상 제도를 운영하고 있습니다.
                        </span>
                    </li>
                </ul>
            </div>
            <div class="swiper-pagination"></div>
        </div>
    </section>
    <section class="rolemodel">
        <span>새로운 Role Model이 되고 싶다면?</span>
        <p>지금 바로. 잡으세요!<br>당신이 그 기회의 주인공 입니다.</p>
        <em>※ 본 화면은 보험설계사를 모집하는 것으로<br>정규 또는 비정규 직원채용과 무관합니다.</em>
        <img src="/img/recruit/recruitInfo/img_rolemodel.png" alt="">
    </section>
</main>
<!-- /main -->
</template>

<style scoped lang="scss">
    @import '@/assets/scss/recruit/recruitInfo.scss';
</style>

<script>
    export default {}
</script>