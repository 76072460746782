<template>
    <aside id="sns_share" class="sns">
        <div class="sns-inner">
            <!-- <a href="javascript:void(0)" class="btn-blog"><img class="ico" src="" data-images-path="/image/sns/blog01.svg" alt="블로그"></a> -->
            <!-- <a href="javascript:void(0)" class="btn-fb"><img class="ico" src="" data-images-path="/image/sns/facebook01.svg" alt="Facebook"></a> -->
            <!-- <a href="javascript:void(0)" class="btn-kko"><img src="" alt="카카오스토리"></a> -->
            <a href="javascript:void(0)" class="btn-kakao"><img class="ico" src="" data-images-path="/image/sns/kakaotalk01.svg" alt="카카오"></a>
            <!-- <a href="javascript:void(0)" class="btn-instar"><img class="ico" src="" data-images-path="/image/sns/instagram01.svg" alt="Instargram"></a> -->
            <!--<a href="javascript:void(0)" class="btn-url"><img src="/img/promotion/ico-url.png" alt="URL"></a>-->
            <!--<a href="javascript:void(0)" class="btn-print"><img src="/img/promotion/ico-print.png" alt="인쇄"></a>-->
            <button type="button" class="btn-img btn-share"><img class="ico" src="" data-images-path="/image/sns/share01.svg" alt="공유하기"></button>
        </div>
    </aside>
</template>


<script type="text/javascript" src="//developers.kakao.com/sdk/js/kakao.min.js"></script>
<script>
import $ from 'jquery'

export default {
    name: 'snsShare',
    mounted() {
        $(function(){ // DOCUMENT READY...
        /*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */(function(){
            // btn share
            $('.btn-share').click(function() {
                $('.sns-inner').toggleClass('show-sns');
            });
            // btn facebook
            $('.sns .sns-inner .btn-fb').click(function(e) {
                e.preventDefault();
                // var url = 'http://www.facebook.com/sharer/sharer.php?u=http://www.facebook.com/peoplelifebiz';
                var pathname = escape(location.href);
                var url = 'http://www.facebook.com/sharer/sharer.php?u='+pathname;
                window.open(url, '', 'width=600,height=600');
            });
            // btn blog
            $('.sns .sns-inner .btn-blog').click(function(e) {
                e.preventDefault();
                var url = encodeURI(encodeURIComponent(window.location.href));
                var title = encodeURI($('.post-title').text() + ' | 피플라이프');
                var shareURL = "http://share.naver.com/web/shareView.nhn?url=" + url + "&title=" + title;
                window.open(shareURL, '', 'width=600,height=600');
            });
            //btn kko
            Kakao.init(getAppkey().kakao);
            $('.sns .sns-inner .btn-kko').click(function() {
                //var url = encodeURI(encodeURIComponent(window.location.href));
                var url = location.href;
                url = url.replace('http://', '');
                Kakao.Story.share({
                    url: url,
                    text: ''
                });
            });
            //btn kakao
            $('.sns .sns-inner .btn-kakao').click(function() {
                var detail_url = location.href;

                var link = location.pathname;
                var findStr = 'news_detail';

                if(link.indexOf(findStr) != -1){
                    Kakao.Link.sendDefault({
                        objectType: 'text',
                        text: $('.news_detail .d_tit').text(),
                        link: {
                            mobileWebUrl: detail_url,
                            webUrl: detail_url
                        }
                    });
                }else{
                    Kakao.Link.sendDefault({
                        objectType: 'text',
                        text: $('.post-header .post-title').text(),
                        link: {
                            mobileWebUrl: detail_url,
                            webUrl: detail_url
                        }
                    });
                }
            });



        })();/*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */});// DOCUMENT READY...
    },
}
</script>



<style lang="scss">
    #sns_share {
        position: fixed; right: 10px; bottom: 32vw; z-index: 10;
        .sns-inner {
            position: relative; width: 64px; height: 64px;
            a {
                display:block;position:absolute;bottom:0;right:0;margin:0 0.8vw 1.4vw 0;transition: all 0.2s ease-out;
                &.btn-blog {
                    bottom: 0;
                }
                &.btn-fb {
                    bottom: 0;
                }
                .ico {
                    display:block;border-radius:100%;font-size:54px;
                }
            }
            .btn-share {
                position: absolute; bottom: 0; right: 0;
                &:focus {
                    outline: none;
                }
                .ico {
                    border:0.8vw solid #4c4c4c;border-radius:100%;font-size:58px;
                }
            }
            &.show-sns {
                a {
            /*&.btn-blog {
                        bottom: 183px;
                    }
                    &.btn-fb {
                        bottom: 122px;
                    }
                    */
                    &.btn-fb {
                    bottom: 244px;
                    }
                    &.btn-blog {
            bottom: 184px;
            }
                    &.btn-kko {
                    bottom: 124px;
                    }
                    &.btn-kakao {
                    bottom: 64px;
                    }
                }
            }
        }
    }
</style>
