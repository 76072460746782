<template>
	<!-- footer -->
	<footer style="display: none;" data-auto-load="false">
		<!-- #award -->
		<div class="common_award">
			<div class="swiper-container">
				<ul class="swiper-wrapper">
					<li class="swiper-slide">
						<div class="in_slide">
							<img src="/img/common/new/img_awards01.png" alt="2년연속(2020-2019) 한국소비자만족지수 1위">
							<p class="tit">
								<em>2024</em>
								프리미엄 브랜드 대상
								<span>(주최:주간동아)</span>
							</p>
						</div>
					</li>
					<li class="swiper-slide">
						<div class="in_slide">
							<img src="/img/common/new/img_awards02.png" alt="2년연속(2020-2019) 한국소비자만족지수 1위">
							<p class="tit">
								<em>2021</em>
								대한민국 소비자 선호도 1위
								<span>(주최:주간동아)</span>
							</p>
						</div>
					</li>
					<li class="swiper-slide">
						<div class="in_slide">
							<img src="/img/common/new/img_awards03.png" alt="TRUST BRAND AWARD 고객이신뢰하는브랜드 대상">
							<p class="tit">
								<em>2년연속(2020-2019)</em>
								한국소비자만족지수 1위
								<span>(주최:한경비즈니스)</span>
							</p>
						</div>
					</li>
					<li class="swiper-slide">
						<div class="in_slide">
							<img src="/img/common/new/img_awards04.png" alt="고객이 직접 선택한 한국고객만족도 1위">
							<p class="tit">
								<em>TRUST BRAND AWARD</em>
								고객이신뢰하는브랜드 대상
								<span>(주최:한경비즈니스)</span>
							</p>
						</div>
					</li>
					<li class="swiper-slide">
						<div class="in_slide">
							<img src="/img/common/new/img_awards05.png" alt="대한민국 고객만족브랜드 대상">
							<p class="tit">
								<em>2019</em>
								대한민국고객만족브랜드 대상
								<span>(주최:주간동아)</span>
							</p>
						</div>
					</li>
					<li class="swiper-slide">
						<div class="in_slide">
							<img src="/img/common/new/img_awards06.png" alt="랭키닷컴 재무설계 컨설팅 분야 1위 (2018.12.18 기준)">
							<p class="tit">
								<em>2019</em>
								한국고객만족도 1위
								<span>(주최:한경비즈니스)</span>
							</p>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<!-- //#award -->

		<!-- 금소법가이드 -->
		<div id="financial_guide"></div>
		<!-- //금소법가이드 -->

		<div class="inner">
			<div class="buttons">
				<button type="button" class="check_inquiry" onclick="LAYER('checkInquiry')">방문판매원 명부 조회</button>
				<button type="button" class="contact_prohibition" onclick="LAYER('contactProhibition')">연락금지요구권 안내</button>
			</div>
			<div class="footer_gnb">
				<ul class="clearfix">
					<li><a href="javascript:void(0)" data-target="policy_privacy">개인정보처리방침</a></li>
					<li><a href="javascript:void(0)" data-target="policy_usege">이용약관</a></li>
					<li><a href="javascript:void(0)" data-target="creditLayer">신용정보활용체제공시</a></li>
					<!-- <li><a href="javascript:void(0)" data-target="externalLayer">외부감사인선임공고</a></li> -->
					<li><a href="javascript:void(0)" data-target="policy_protection">금융소비자보호기준</a></li>
					<br>
					<li><a href="javascript:void(0)" data-target="policy_customer_info">고객정보 취급방침</a></li>
					<!-- <li><a href="javascript:void(0)" data-target="temporary_shareholder_closure_modal">임시 주주총회 기준일 및 주주명부 폐쇄기간 공고</a></li> -->
					<!-- <li><a href="javascript:void(0)" data-target="shareholder_guide_layer">주주 보호 및 조치사항 안내</a></li> -->
					<!-- <li><a href="javascript:void(0)" data-target="stockholdersLayer">기준일 및 주주명부 폐쇄 공고</a></li> -->
					<!-- <li><a href="#saviorshipSubmission_modal" data-overlay="#saviorshipSubmission_modal">구주권제출 및 이의제출공고</a></li> -->
					<!-- <li><a href="javascript:void(0)" data-target="agentLayer">명의개서대리인 선임</a></li>-->
				</ul>
			</div>
			<div class="footer_call clearfix">
				<div class="float-left">
					<ul>
						<li><a href="tel:02-6906-9114" onclick="_LA.EVT('3674')">본사 대표전화 <strong><span data-peoplelife="callCenter"></span></strong></a></li>
						<li><a href="tel:1833-6114" onclick="_LA.EVT('3674')">보험클리닉 <strong>1833-6114</strong></a></li>
					</ul>
				</div>
				<div class="consulBtn float-right">
					<a href="/brand/ceo?freeConsult=corp">상담신청</a>
				</div>
			</div>
			<address>
				<p><span data-peoplelife="address"></span> <span data-peoplelife="addressDetail"></span></p>
				<p><span data-peoplelife="description"></span></p>
				<p>대표이사 : <span data-peoplelife="owner"></span><span class="bar"></span> 사업자등록번호 : <span data-peoplelife="corpNumber"></span><span class="bar"></span>&nbsp;&nbsp;<button class="agency_certificate" onclick="LAYER('agencyCertificate')">대리점등록증</button></p>
				<p data-thispage="deliberation"></p>
				<p>본 광고는 광고심의기준을 준수하였으며, 유효기간은 심의일로부터 1년입니다.</p>
				<p class="copyright">Copyright 2020. Peoplelife all rights reserved.</p>
			</address>
			<div class="footer_select">
				<button type="button" class="sitetBtn">family site</button>
				<div class="siteBox">
					<ul>
						<li><a href="//m.bohumclinic.com" target="_blank">보험클리닉</a></li>
						<li><a href="//m.bohumclinic.com/visit" target="_blank">개인영업(FA)</a></li>
						<!-- <li><a href="//ceo-clinic.co.kr" target="_blank" >CEO클리닉</a></li> -->
					</ul>
				</div>
			</div>
		</div>
		<button class="topBtn" data-target="#wrap" data-focus="">TOP</button>
	</footer>
	<!-- //footer -->
</template>



<script src="/plugin/moment/moment-with-locales.min.js"></script>
<script>
	var date = parseInt('${now}'.replace(/,/gi, ''));
	var days = new Date(date).getDay();
	var phone_number = '';
	var nowTime = new Date(moment(date)).getTime();
	var nine = new Date(moment(date).hour(9).minute(0)).getTime();
	var six = new Date(moment(date).hour(18).minute(30)).getTime();

	if (days === 1 || days === 2 || days === 3 || days === 4 || days === 5) {
		if (nowTime >= nine && nowTime < six) {
			phone_number = '1833-6114';
		} else {
			phone_number = '080-203-3300';
		}
	} else {
		phone_number = '080-203-3300';
	}
	var change_number = document.getElementsByClassName('counseling_phone_number');
	for (var i = 0; i < change_number.length; i++) {
		change_number[i].innerText = phone_number;
	}
</script>

<script>
	export default {

	}
</script>