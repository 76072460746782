<template>
    <main id="container">
        <section class="visual">
            <p>피플라이프의 재무현황을 알려드립니다.</p>
        </section>
        <section id="finance_list">
            <div class="inner">
                <div class="tit_box">
                    <h2 class="tit_section">재무현황공시</h2>
                    <p class="desc_section">피플라이프의 주요 실적자료를 확인하실 수 있습니다.</p>
                </div>
                <div class="board_list">
                    <p class="total_number">총 <strong>4</strong>건</p>
                    <ul>
                        <!-- /finance/list.json -->
                        <li class="item hidden">
                            <a href="javascript:void(0)">
                                <!-- <i data-type="id"></i> -->
                                <strong data-type="title"></strong>
                                <p data-type="date"></p>
                            </a>
                        </li>
                    </ul>
                </div>
			</div>
        </section>
    </main>
</template>

<script>
    export default {}
</script>

<style scoped lang="scss">
	@import '@/assets/scss/intro/finance.scss';
</style>