<template>
<!-- main -->
<main id="container" class="newScrollAction" data-name="efa">
    <h1 class="screen_out">찾아가는 보험클리닉(Multi)</h1>

    <!-- 메인비주얼 -->
    <section class="visual">
        <div class="txt_box">
            <em><img :src="imgPath+'efa_logo.png'" alt="EFA"></em>
            <p>단순한 보험 판매를 넘어</p>
            <strong>생애 전반의 라이프 케어 전문 컨설팅</strong>
        </div>
    </section>
    <!-- //메인비주얼 -->

    <!-- 패러다임 -->
    <section class="information section">
        <h2 class="title"><img :src="imgPath+'efa_title.png'" alt="EFA"></h2>
        <div class="exp">
            <div>
                <p class="img"><img :src="imgPath+'pic_efa_family.jpg'" alt=""></p>
                <p class="tit">
                    찾아가는 보험클리닉<br>
                    <strong>「  Multi  」</strong>
                </p>
                <p class="txt">
                    피플라이프만의 철저한 교육 시스템으로 무장한<br>
                    Multi 채널은 정규직 찾아가는 보험클리닉 EFA와<br>
                    외근형 찾아가는 보험클리닉 MFA로 구성되어 있습니다.
                    <br><br>
                    교육을 받은 전문가가 고객님의 고민을 듣고<br>
                    보험을 문진하고 처방하는 종합 컨설팅<br>
                    서비스를 제공합니다.
                </p>
            </div>
        </div>
    </section>
    <!-- //패러다임 -->

    <!-- 차별화 -->
    <section class="stepwheel section">
        <h3 class="title">정규직 EFA 차별화</h3>
        <div class="stepwheel_slide">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide"><img :src="imgPath+'pic_stepwheel01.jpg?v=230120'" alt="차별화 01. 고용의 안정성"></div>
                    <div class="swiper-slide"><img :src="imgPath+'pic_stepwheel02.jpg?v=230120'" alt="차별화 02. 동기부여"></div>
                    <div class="swiper-slide"><img :src="imgPath+'pic_stepwheel03.jpg?v=230120'" alt="차별화 03. 고소득 실현"></div>
                </div>
            </div>
            <div class="pagination">
                <div class="count">
                    <span class="num"></span> / <span class="total"></span>
                </div>
                <div class="swiper-pagination"></div>
            </div>
        </div>
    </section>
    <!-- //차별화 -->

    <!-- 차이점 -->
    <section class="different section">
        <h3 class="title">
            왜, 피플라이프 <br>
            정규직 보험상담매니저는 다를까요?
        </h3>
        <p class="img"><img :src="imgPath+'pic_efa_different01.jpg?v=23001271'" alt=""></p>
        <div class="swiper-container">
            <ul class="swiper-wrapper">
                <li class="swiper-slide">
                    <strong>편리함</strong>
                    <em>#언제 #어디서나 #쉽게</em>
                    <span>
                        고객님이 요청한 시간, 장소로 직접 방문하여<br>
                        1:1 맞춤 컨설팅을 진행합니다.
                    </span>
                </li>
                <li class="swiper-slide">
                    <strong>편안함</strong>
                    <em>#부담없는 #보험컨설팅</em>
                    <span>
                        EFA 정규직 보험상담 매니저는 월 고정 급여를 <br>
                        받는 본사 소속 상담사로 보험 가입을 <br>
                        권유하지 않습니다.
                    </span>
                </li>
                <li class="swiper-slide">
                    <strong>객관성</strong>
                    <em>#고객만족 #최우선</em>
                    <span>
                        EFA 상담 매니저는 판매 수당에 대한 부담이 없어, <br>
                        객관적으로 고객님께 가장 유리한 상품만을 <br>
                        추천합니다.
                    </span>
                </li>
            </ul>
            <div class="swiper-pagination"></div>
        </div>
    </section>
    <!-- //차이점 -->

    <!-- 서비스 -->
    <section class="service section">
        <h3 class="title">피플라이프 EFA 차별화 서비스</h3>
        <div class="service_contents">
            <p class="img"><img :src="imgPath+'pic_efa_step01.jpg'" alt=""></p>
            <div class="service_slide">
                <dl class="list1" style="display:block;">
                    <dt>「 고객 맞춤형 방문 서비스 제공 」</dt>
                    <dd> 365일 언제 어디서나!<br> 고객님이 요청한 시간, 장소로 EFA가 직접 방문하여 <br> 1:1 맞춤형 컨설팅을 진행합니다. 고객님의 얘기를 <br> 직접 듣고 최적의 솔루션을 제공합니다. </dd>
                </dl>
                <dl class="list2">
                    <dt>「 무료 상담 서비스 」</dt>
                    <dd> 기존 보험 점검, 리모델링을 위한 상담 비용 ZERO!<br> 피플라이프 전문가가 보험 점검부터 관리까지 무료로 <br> 전담하며 상담이 완료된 후 맞춤보장분석 보고서를 <br> 무료로 제공합니다. </dd>
                </dl>
                <dl class="list3">
                    <dt>「 주요 보험사 상품 비교 분석 」</dt>
                    <dd> 피플라이프는 국내 주요 보험사와의 제휴로 상품 포트폴리오를 실시간 비교 분석하여 고객에게 유리한 상품을 추천합니다. </dd>
                </dl>
                <dl class="list4">
                    <dt>「 최적 전문가 그룹 」</dt>
                    <dd> 단순한 보험 비교를 넘어 이론과 실전 경험을 <br> 갖춘 피플라이프 전문가 네트워크를 통해 생애 전반의 <br> 라이프 케어 및 자산관리 컨설팅 등 고객님의 <br> 풍요로운 미래를 위해 노력합니다. </dd>
                </dl>
                <div class="swiper-container">
                    <ul class="swiper-wrapper">
                        <li class="swiper-slide">
                            <span>
                                고객 맞춤형<br>
                                방문 서비스 제공
                            </span>
                            <img :src="imgPath+'pic_efa_step01.jpg'" alt="">
                        </li>
                        <li class="swiper-slide">
                            <span>
                                무료 상담 서비스
                            </span>
                            <img :src="imgPath+'pic_efa_step02.jpg'" alt="">
                        </li>
                        <li class="swiper-slide">
                            <span>
                                국내 주요 보험사<br>
                                상품 비교 분석
                            </span>
                            <img :src="imgPath+'pic_efa_step03.jpg'" alt="">
                        </li>
                        <li class="swiper-slide">
                            <span>
                                업계 최적의<br>
                                전문가 그룹
                            </span>
                            <img :src="imgPath+'pic_efa_step04.jpg'" alt="">
                        </li>
                    </ul>
                </div>
                <div class="counter">
                    <span class="num">01</span> / <span class="total"></span>
                </div>
                <div class="swiper-pagination"></div>
            </div>
        </div>
    </section>
    <!-- 서비스 -->

    <!-- 전문가 네트워크 -->
    <networkTab />
    <!-- //전문가 네트워크 -->

    <!-- 수상내역 -->
    <award2020 />
    <!-- //수상내역 -->

    <!-- 업계 no.1 피플라이프 보험컨설팅 -->
    <bohumConsulting />
    <!-- //업계 no.1 피플라이프 보험컨설팅 -->    

    <!-- 프로세스 -->
    <section class="process section">
        <h3 class="title">고객 맞춤형 컨설팅 프로세스</h3>
        <div class="process_etc">
            <button type="button" class="prev">이전</button>
            <button type="button" class="next">다음</button>
            <div class="swiper-pagination"></div>
        </div>
        <div class="swiper-container">
            <ol class="swiper-wrapper">
                <li class="swiper-slide">
                    <i><b>1</b><br>STEP</i>
                    <p class="img"><img :src="imgPath+'pic_efa_process_img01.jpg'" alt=""></p>
                    <dl>
                        <dt>상담 신청</dt>
                        <dd>상담을 원하시는 고객님의 기본적인 정보를 등록해 주세요.</dd>
                    </dl>
                </li>
                <li class="swiper-slide">
                    <i><b>2</b><br>STEP</i>
                    <p class="img"><img :src="imgPath+'pic_efa_process_img02.jpg'" alt=""></p>
                    <dl>
                        <dt>보험전문가 FA 지정</dt>
                        <dd>수많은 경험과 실전 지식을 보유한 고객 맞춤 보험 전문가 FA가 지정됩니다. </dd>
                    </dl>
                </li>
                <li class="swiper-slide">
                    <i><b>3</b><br>STEP</i>
                    <p class="img"><img :src="imgPath+'pic_efa_process_img03.jpg'" alt=""></p>
                    <dl>
                        <dt>고객상담(고객분석)</dt>
                        <dd>
                            고객님이 현재 보유한 보험의 보장 및 보험료가 적정한지 확인합니다.
                            투자 성향, 재무 상황 및 미래의 계획을 상담하고 고객님의 보험 및 자산을 분석합니다.
                        </dd>
                    </dl>
                </li>
                <li class="swiper-slide">
                    <i><b>4</b><br>STEP</i>
                    <p class="img"><img :src="imgPath+'pic_efa_process_img04.jpg?v=230127'" alt=""></p>
                    <dl>
                        <dt>상품 비교 및 분석</dt>
                        <dd>
                            피플라이프만의 지능형 보장분석 시스템 어시스트(@ssit)를 통해
                            주요 보험사 상품을 비교 분석합니다.
                        </dd>
                    </dl>
                </li>
                <li class="swiper-slide">
                    <i><b>5</b><br>STEP</i>
                    <p class="img"><img :src="imgPath+'pic_efa_process_img05.jpg'" alt=""></p>
                    <dl>
                        <dt>자산관리 컨설팅 지원</dt>
                        <dd>
                            CFP팀이 고객님의 증권을 분석하고 금융 연구소에서
                            보험 비교를 넘어 생애 전반의 라이프 케어 및 자산관리 컨설팅을 지원합니다.
                        </dd>
                    </dl>
                </li>
                <li class="swiper-slide">
                    <i><b>6</b><br>STEP</i>
                    <p class="img"><img :src="imgPath+'pic_efa_process_img06.jpg?v=230120'" alt=""></p>
                    <dl>
                        <dt>맞춤 포트폴리오 제안</dt>
                        <dd>
                            차별화된 상품 포트폴리오로 ‘보험사’가 아닌<br>
                            고객님에게 유리한 상품만을 추천한 맞춤 포트폴리오를 제안합니다.
                        </dd>
                    </dl>
                </li>
                <li class="swiper-slide">
                    <i><b>7</b><br>STEP</i>
                    <p class="img"><img :src="imgPath+'pic_efa_process_img07.jpg'" alt=""></p>
                    <dl>
                        <dt>상담 후 평생관리</dt>
                        <dd>
                            보험금 청구 시스템 및 지속 관리를 통해 고객 편의성을 증대시키고,
                            정기적인 검진을 통해 고객 만족을 위해 노력합니다.
                        </dd>
                    </dl>
                </li>
            </ol>
        </div>
    </section>
    <!-- //프로세스 -->

    <!-- 안내사항 -->
    <!-- <section id="about" class="section">
        <div class="inner">
            <p class="desc_section">고용을 넘어 안정적인 소득을 원하신다면? </p>
            <h3 class="tit_section">정규직 보험상담매니저에 도전하세요!<br><strong>당신이 그 기회의 주인공 입니다.</strong></h3>
            <a class="link_about" href="/recruit/recruitEfa"><span>정규직 보험클리닉(EFA) 인재채용 바로가기</span><img src="" data-images-path="/image/svg/arr_right01.svg" alt="앞으로"></a>                      
        </div>
    </section> -->
    <!-- //안내사항 -->
</main>
<!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/sales/efa/'
        }
    }
}
</script>



<style lang="scss" scoped>
    @import '@/assets/scss/sales/efa.scss';
</style>
