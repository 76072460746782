<template>
<!-- main -->
<main id="container" class="newScrollAction">
    <h1 class="screen_out">법인컨설팅 채용</h1>

    <!-- 메인 -->
    <section id="visual" class="section">
        <div class="inner">
            <p class="desc_section">명실상부 법인컨설팅의 명가</p>
            <h3 class="tit_section">피플라이프</h3>
            <img class="model" :src="imgPath+'visual_model.png'" alt="사람들">
        </div>
    </section>
    <!-- //메인 -->

    <!-- 차별화 -->
    <section id="advantage" class="section">
        <div class="inner">
            <h3 class="tit_section">법인 컨설팅의 차별화</h3>
            <ul class="list_advantage">
                <li class="item_advantage isAppear">
                    <img class="thumb" :src="imgPath+'advantage_thumb1.jpg'" alt="">
                    <div class="box">
                        <p class="tit">수익성</p>
                        <p class="headline"><mark>“개인영업과는 차원이 다른 기업 컨설팅입니다”</mark></p>
                        <p class="desc">기업 컨설팅의 경우 개인영업보다 고연봉 직군에 속하며<br>법인 CEO DB가 무료(주 2회 이상) 제공됩니다.<br>월초 보험료 100만 이상의 고액 계약이 주로<br>이루어집니다. </p>
                    </div>
                </li>
                <li class="item_advantage isAppear">
                    <img class="thumb" :src="imgPath+'advantage_thumb2.jpg'" alt="">
                    <div class="box">
                        <p class="tit">전문성</p>
                        <p class="headline"><mark>“전문가에 의해 전문가로 육성됩니다.”</mark></p>
                        <p class="desc">중소기업 CEO를 위한 세무/노무/법률/부동산 1:1<br>전문가 상담 서비스가 가능합니다.<br>전화, 상담 예약, 실시간 질문 등 십수 년의 수많은<br>케이스를 보유한 전문가가 있습니다.<br> 전문가와 영업 멘토의 온 오프라인 및 현장 교육을 지속<br>운영합니다. </p>
                    </div>
                </li>
            </ul>
        </div>
    </section>
    <!-- //차별화 -->

    <!-- 핵심포인트 -->
    <section id="point" class="section">
        <div class="inner">
            <h3 class="tit_section">피플라이프만의 법인 핵심 POINT!</h3>
            <ul class="list_point">
                <li class="item_point isAppear">
                    <p class="tit">강력한 법인 컨설팅 교육 </p>
                    <p class="desc mb-20 pl-25"><i class="bullet">•</i>신입교육 3주 / 동반교육 8주</p>
                    <p class="desc mb-20 pl-25"><i class="bullet">•</i>차월별 · 분기별 심화교육</p>
                    <p class="desc pl-25"><i class="bullet">•</i>매주 월,수 저녁교육 및 토요특강<br>(외부전문가)</p>
                    <img class="thumb" :src="imgPath+'point_thumb1.jpg'" alt="">
                </li>
                <li class="item_point isAppear">
                    <p class="tit">활동  DB 제공</p>
                    <p class="desc mb-20 pl-25"><i class="bullet">•</i>주당 3~5개 지인이 아닌 배정된<br>업체 방문</p>
                    <p class="desc pl-25"><i class="bullet">•</i>세무 및 기타 해결사항 담당<br> 세무사와 동행</p>
                    <img class="thumb" :src="imgPath+'point_thumb2.jpg'" alt="">
                </li>
                <li class="item_point isAppear">
                    <p class="tit">신입 정착을 위한 멘토링</p>
                    <p class="desc lh-21">지정 멘토(팀장) → 지점장 → <br> 사업단장, 본부장을 통한 조기<br> 정착에 도움</p>
                    <img class="thumb" :src="imgPath+'point_thumb3.jpg'" alt="">
                </li>
                <li class="item_point isAppear">
                    <p class="tit">정착지원금</p>
                    <p class="desc lh-21">초기 정착을 위한 정착지원금을<br> 제공하며, 컨설팅 경력대상에<br> 따라 차등 지급</p>
                    <img class="thumb" :src="imgPath+'point_thumb4.jpg'" alt="">
                </li>
            </ul>
        </div>
    </section>
    <!-- 핵심포인트 -->

    <!-- 컨설팅지원 -->
    <section id="support" class="section">
        <h3 class="tit_section">법인 컨설팅 지원시스템</h3>
        <ol class="list_support isAppear">
            <li class="item_support isAppear">
                <div class="thumb">
                    <img :src="imgPath+'support_thumb1.jpg'" alt="사진">
                    <p class="num">01</p>
                </div>
                <p class="tit">DB 지원</p>
                <p class="desc">공시된 법인 기업의 정보를 피플라이프 만의 시스템을 통해 섭외 부서를 통해 DB를 제공하고 있습니다.</p>
            </li>
            <li class="item_support isAppear">
                <div class="thumb">
                    <img :src="imgPath+'support_thumb2.jpg'" alt="사진">
                    <p class="num">02</p>
                </div>
                <p class="tit">전문가 지원</p>
                <p class="desc">기업의 경영과 소유 과정에서 발생되는 문제점을 해결하기 위해서는 각 분야에 대해 전문적인 실전 경험을 갖춘 인력이 필요합니다.<br>피플라이프는 중소기업 경영지원 컨설팅 분야에서 뛰어난 솔루션을 보유한 전문가 그룹과 제휴관계를 맺고 있습니다.</p>
                <a class="btn_info" href="/intro/network" target="_blank"><span>자세히보기</span><img class="ico" src="" data-images-path="/image/svg/arr_right01.svg"></a>
            </li>
            <li class="item_support isAppear">
                <div class="thumb">
                    <img :src="imgPath+'support_thumb3.jpg'" alt="사진">
                    <p class="num">03</p>
                </div>
                <p class="tit">교육지원</p>
                <p class="desc">법인 대상 컨설팅 영업을 조직화하여 사업적 비전과 품격을 한 단계 업그레이드한 원동력은 바로 체계적인 교육의 힘입니다.</p>
                <button class="btn_info" type="button"><span>교육과정 상세</span><img class="ico" src="" data-images-path="/image/svg/plus01.svg"></button>
                <div class="inner group_detail">
                    <table class="table">
                        <colgroup>
                            <col style="width:20%;">
                            <col style="width:30%;">
                            <col style="width:50%;">
                        </colgroup>
                        <thead>
                            <tr>
                                <th scope="col">교육과정</th>
                                <th scope="col">구분</th>
                                <th scope="col">교육대상</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row" rowspan="2"><strong class="color-008ed4">10일</strong><br>신입교육</th>
                                <td>법인영업부문</td>
                                <td>법인영업 신입 FA</td>
                            </tr>
                            <tr>
                                <td class="left" colspan="2">
                                    <ul>
                                        <li>&middot; 법인운영과 개인사업자 법인전환 컨설팅</li>
                                        <li>&middot; 법인세법, 소득세법, 상법 등 이론과 프로세스 습득</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" rowspan="4"><strong class="color-008ed4">월간</strong><br>정기교육</th>
                                <td>주간정기교육</td>
                                <td>법인영업사업단</td>
                            </tr>
                            <tr>
                                <td class="left" colspan="2">
                                    <ul>
                                        <li>&middot; (월) 세무 집중화 교육</li>
                                        <li>&middot; (수) 금융, 노무, 부동산, 법률교육</li>
                                        <li>&middot; (목) 컨셉 및 상품교육</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>토요 특강</td>
                                <td>법인영업사업단</td>
                            </tr>
                            <tr>
                                <td class="left" colspan="2">
                                    <ul>
                                        <li>&middot; 신규 시장이슈와 영업기법에 대한 지속적 교육</li>
                                        <li>&middot; 법인영업 Best Practice – FA 및 전문가 강좌</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" rowspan="2"><strong class="color-008ed4">신입</strong><br>보수과정</th>
                                <td>4·7 차월<br>교육과정 (1박2일 진행)</td>
                                <td>입사 1년 미만 법인 신입FA</td>
                            </tr>
                            <tr>
                                <td class="left" colspan="2">
                                    <ul>
                                        <li>&middot; 세무 – 임원보수와 세금, 퇴직금 마련 플랜</li>
                                        <li>&middot; 세무 – 자사주, 퇴직금 중간정산</li>
                                        <li>&middot; 세무 – 세무조정계산서와 재무제표의 이해</li>
                                        <li>&middot; 상법 – 정관의 이해</li>
                                        <li>&middot; 상품솔루션 활용 Skill</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" rowspan="2"><strong class="color-008ed4">1박2일</strong><br>체계화과정</th>
                                <td>격월진행</td>
                                <td>법인FA대상</td>
                            </tr>
                            <tr>
                                <td class="left" colspan="2">
                                    <ul>
                                        <li>&middot; 세무조정 계산서 이해</li>
                                        <li>&middot; 세금포트폴리오 & 회계의 기초</li>
                                        <li>&middot; 정관분석</li>
                                        <li>&middot; 크레탑&재무제표 분석</li>
                                        <li>&middot; 세금체계화 가업승계 과정</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" rowspan="2"><strong class="color-008ed4">1박2일</strong><br>심화과정</th>
                                <td>1박2일 심화과정</td>
                                <td>법인FA대상 반기1회</td>
                            </tr>
                            <tr>
                                <td class="left" colspan="2">
                                    <ul>
                                        <li>&middot; 상법 – 경영권방어와 지분구조</li>
                                        <li>&middot; 상품 – 新 시장 재물보험, 배상책임보험 컨설팅</li>
                                        <li>&middot; 세무 – 재무구조개선과 신용도 제고</li>
                                        <li>&middot; 세무 – 가업승계와 세대이전 플랜</li>
                                        <li>&middot; 노무 – 노무컨설팅</li>
                                        <li>&middot; 사례 – 新 마케팅 솔루션</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </li>
            <li class="item_support isAppear">
                <div class="thumb">
                    <img :src="imgPath+'support_thumb4.jpg'" alt="사진">
                    <p class="num">04</p>
                </div>
                <p class="tit">동행지원</p>
                <p class="desc">안정적인 정착을 위해 지점장, 사업단장, 본부장이 동행하여 지원하고 있습니다.</p>
            </li>
            <li class="item_support isAppear">
                <div class="thumb">
                    <img :src="imgPath+'support_thumb5.jpg'" alt="사진">
                    <p class="num">05</p>
                </div>
                <p class="tit">골프·호텔 세미나 지원</p>
                <p class="headline">국내 최대규모 아마추어 골프대회,<br>피플라이프 중소기업 CEO GOLF CLASSIC</p>
                <p class="desc">대규모 ‘피플라이프 중소기업 CEO Golf Classic’ 을 위해 전국에 다수의 골프장과 제휴하는 등 VIP 세미나를 위한 인프라를 구축하고 있습니다.</p>
            </li>
        </ol>
    </section>
    <!-- //컨설팅지원 -->

    <!-- 방문상담신청 -->
    <section id="applyForm"  class="section">
        <div class="inner">
            <h3 class="tit_section"><mark>법인컨설팅 입사 지원하기</mark></h3>
            <li class="row row_file row_css" data-check-pass="false" data-inbound-prop="fileAttachId" data-inbound-value="" data-check-comment="입사지원서를 업로드하세요">
                <button type="button"><img class="ico" src="" data-images-path="/image/svg/clip01.svg"></button>
                <span class="file_name"></span>
                <input type="file" class="hidden">
            </li>
            <div class="render_form"></div>
        </div>
    </section>
    <!-- //방문상담신청 -->

    <!-- FAQ -->
    <section id="faq" class="section">
        <div class="inner">
            <h3 class="tit_section">FAQ</h3>
            <ul class="list_faq">
                <li class="item_faq isAppear">
                    <div class="question">경력이 없어도 입사할 수 있나요?</div>
                    <div class="answer">회사 소정의 교육(이론 및 현장실습)을 이수하시면 처음 하시는 분도 가능하십니다. 다만 회계, 세무 관련 경력이 있으면 더 빠르게 업무에 적응할 수 있습니다.</div>
                </li>
                <li class="item_faq isAppear">
                    <div class="question">처음 일을 시작해서 걱정돼요.</div>
                    <div class="answer">초기 6개월간 경력자와 동행하여 상담이 진행됩니다. 처음에는 경력자의 상담을 보좌하는 수준에서 점차적으로 상담을 주도하는 능력을 배양합니다.</div>
                </li>
                <li class="item_faq isAppear">
                    <div class="question">세무, 회계에 대한 지식이 없는데 업무 가능한가요?</div>
                    <div class="answer">회사의 교육(신입교육, 보수교육, 정기교육, 온라인 교육)과 더불어 상담 시 전속 자문 법인 세무사가 상담을 도와드립니다. 당사에서는 회계 및 세무 관련  R&D센터를 운영하여 업무를 지원하고 있습니다.</div>
                </li>
                <li class="item_faq isAppear">
                    <div class="question">어떤 법인을 대상으로 컨설팅을 하나요?</div>
                    <div class="answer">회사 섭외 팀에서 미팅이 약속된 법인을 대상으로 컨설팅을 하게 됩니다.</div>
                </li>
            </ul>
        </div>
    </section>
    <!-- //FAQ -->

    <!-- 안내사항 -->
    <section id="about" class="section">
        <span>명실상부, 법인컨설팅의 명가!</span>
        <p>피플라이프와 함께하세요!<br><strong>다음 성공의 주인공은 당신입니다.</strong></p>
        <em>※ 본 화면은 보험설계사를 모집하는 것으로<br>정규 또는 비정규 직원채용과 무관합니다.</em>
        <img class="model" :src="imgPath+'about_model.png'" alt="">
    </section>
    <!-- //안내사항 -->

    <!-- 입사문의 바로가기 floating -->
    <aside id="banner_apply">
        <div class="inner">
            <button class="btn_apply anchor" data-target="#applyForm" title="입사문의 바로가기" type="button">
                <img :src="imgPath+'banner_apply_bg.png'" alt="">
            </button>
        </div>
    </aside>
    <!-- //입사문의 바로가기 floating -->
</main>
<!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/recruit/recruitCorp/'
        }
    }
}
</script>



<style lang="scss" scoped>
    @import '@/assets/scss/recruit/recruitCorp.scss';
</style>