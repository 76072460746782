<template>
<!-- main -->
<main id="container" data-name="complete">
    <section class="individual">
        <div class="indi_contents">
            <p class="txt">보험진단부터 리모델링, 비교 추천까지!</p>
            <p class="logo"><img data-images-path="/image/logo/peoplelife_basic.svg" src="" alt="피플라이프"></p>
            <p class="description">
                1:1 맞춤 상담신청이 완료되었습니다.<br>
                기재해주신 번호로 <strong>1~2일 이내</strong>에 전화 드리겠습니다.
                <br><br>
                감사합니다.
            </p>
            <p class="img"><img src="/img/brand/complete/man.png?v=230125" alt=""></p>
        </div>
        <div class="complete_btn_area">
            <a href="javascript:history.back();">이전화면으로 이동</a>
        </div>
    </section>
</main>
<!-- /main -->
</template>



<style scoped lang="scss">
/*
■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
*/



    .complete_btn_area{
        padding:6vw 0;
        text-align: center;
        a{
            display:inline-block;
            width:38vw;
            height:12vw;
            line-height:12vw;
            font-size:3.4vw;
            color:#000;
            border:0.26666vw solid #000;
        }
    }



/*
■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
*/



    .individual{
        .indi_contents{
            padding:8vw 5.3333vw 6vw;
            border-bottom:0.26666vw solid #000;
            .txt{
                text-align:center;
                font-size: 5vw;
                color: #0383c2;
            }
            .logo{
                font-size: 15vw;text-align: center; margin-top:4vw;
            }
            .description{
                position: relative;
                width:100%;
                margin-top:8vw;
                padding:5.2vw;
                font-size:3.4vw;
                color:#333;
                border-radius: 2vw;
                background-color: #f1f1f1;
                &::after{
                    content: '';
                    position: absolute;
                    left: 19vw;
                    bottom: -12vw;
                    width: 0px;
                    height: 0px;
                    border-top: 9vw solid #f1f1f1;
                    border-left: 5vw solid transparent;
                    border-bottom: 5vw solid transparent;
                }
            }
            .img{
                margin-top:4vw;
                img{
                    width:35.5333vw;
                }
            }
        }
    }



/*
■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
*/



    .corporation{
        .corp_contents{
            padding:20vw 5.3333vw 6vw;
            border-bottom:0.26666vw solid #000;
        }
        .description{
            margin-top:4vw;
            text-align: center;
            font-size:4vw;
            color:#333;
        }
        .img{
            margin-top:6vw;
            img{
                width:53vw;
            }
        }
    }



/*
■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
*/



    .complate-container{
        position: relative; 
        overflow: auto;
        height: 100%;
        max-height: none;
        
        .complete_btn_area a{
            color:#fff;
            border:0.26666vw solid #fff;
        }
        .complete_logo_wrap {
            position: absolute;
            top:0;
            left:0;
            .logo { 
                margin: 14% 0 0 17%; 
                img{
                    width: 40vw;
                }
            }
        }
    
        .complete__contents {
            position: relative;
            width: 100%;
            text-align: center;
            .img{
                width: 44vw;
            }
            h2 {
                margin-top: 5vw;
                font-size: 5vw;
                font-weight: 700;
                line-height: 1.5;
            }
            .txt{
                font-size: 4vw;
            }
            div.complete_cont {
                margin-top: 35vw;
                font-size: 16px;
                p { 
                    margin-bottom: 5px; 
                }
            }
    
            .btn {
                margin-top: 3.8vw;
                padding-left: 15px;
                padding-right: 15px;
            }
            .complete_jobform{
                display:none;
                margin-top: 15vw;
                width: 100%;
                .complete_inform{
                    padding: 7vw 4vw 5vw;
                    width: 100%;
                    height:100%;
                    color:#fff;
                    background-color: #3a478f;
                    letter-spacing: -0.025em;
                    h5{
                        font-size:4.6vw;
                        line-height: 1.5;
                        margin: 0;
                    }
                    .complete_desc{
                        line-height: 1.3;
                        span{
                            color:#bec8ff; 
                            font-size:3.5vw
                        }
                    }
                    .complete_ps{
                        font-size:3.6vw;
                        margin-top: 5vw;
                        margin-bottom: 0;
                    }
                    ul{padding:0; 
                        margin-top: 3vw;
                        li{
                            position: relative; 
                            width: 100%;
                            height: 13vw; 
                            line-height: 12vw; 
                            border:1px solid #fff; 
                            text-align: center;  
                            list-style: none;
                            &::before{
                                content: ''; 
                                position: absolute;
                                top:50%; 
                                left:6vw; 
                                width: 6.5vw; 
                                height: 5vw; 
                                transform: translateY(-50%);
                            }
                            &:first-child::before{
                                background: url(/img/common/ico/icon_download.png)0 0 no-repeat; 
                                background-size: contain;
                            }
                            &:last-child::before{
                                background: url(/img/common/ico/icon_malito.png)0 0 no-repeat; 
                                background-size: contain;
                            }
                            a{
                                color:#fff; 
                                font-size:4.7vw; 
                                font-weight: 700;
                            }
                        }
                        li+li{
                            margin-top: 3.9vw;
                        }
                    }
                }
            }
        }
        
    }



/*
■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
*/
</style>



<script>
import $ from 'jquery'

export default {
    mounted() {
        $(function(){ // DOCUMENT READY...
        /*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */(function(){



            $('#twodeps').find('[data-name="ceo"]').addClass('on'); //LNB 위치 표시
            $('footer').hide();
            

        })();/*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */});// DOCUMENT READY...
    }
}
</script>