<template>
    <!-- main -->
    <main id="container">
        <section id="invitation">
            <div class="inner">
                <!-- <h2>안내</h2> -->
                <div class="img">
                    <img src="/img/etc/invitation/24_invitation2.jpg?v=0213" alt="" onclick="window.open(this.src)">
                </div>
            </div>
        </section>
    </main>
<!-- /main -->
</template>




<style scoped lang="scss">
/*
■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
*/
 #invitation h2{margin: 0 0 8vw 0; text-align: center; font-size: 6.4vw; font-weight: 500; color: #000013; line-height: 1.4em;}
 #invitation .img{margin: 0 0 8vw 0;}
</style>
    

<script>
    import $ from 'jquery'
    
    export default {
        mounted() {
            $(function(){ // DOCUMENT READY...
            /*
            ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
            */(function(){
    
    
    
                $('header, footer').remove()
                $('#wrap').css({
                    'padding-top' : 0,
                    'display' : 'flex',
                    'align-items' : 'center',
                    // 'height' : '80vh'
                })
                
    
            })();/*
            ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
            */});// DOCUMENT READY...
        }
    }
    </script>