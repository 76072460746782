<template>
<!-- main -->
    <main id="container" class="newScrollAction" data-name="branch">
        <h1 class="screen_out">사업단찾기</h1>

        <section class="find_contents">
            <h3 class="title">사업단찾기</h3>
            <div class="tab_menu">
                <button type="button" class="alls on" data-name="전체" data-gubun="">전체</button>
                <button type="button" class="bohum" data-name="보험클리닉" data-gubun="4">보험클리닉</button>
                <button type="button" class="tm" data-name="TFA" data-gubun="5">TM센터</button>
                <button type="button" class="individual" data-name="개인" data-gubun="2">개인사업단</button>
                <button type="button" class="corporation" data-name="법인" data-gubun="1">법인사업단</button>
            </div>
            <div class="search_box">
                <label for="">
                    <select name="" id="">
                        <option value="">전체</option>
                    </select>
                    <u></u>
                </label>
                <input type="text" placeholder="지역 및 사업단명 입력" id="keyword">
                <button type="button" id="search">검색</button>
                <p class="txt">
                    <span class="region"></span> 지역 총 <span><strong class="length"></strong>개</span> 사업단이 검색되었습니다.
                </p>
            </div>
            <div class="tab_wrap">
                <div class="dimd"></div>
                <ul class="tab_contents">
                    <li>
                        <div class="info">
                            <span><img src="" alt=""></span>
                            <strong></strong>
                            <address class="clamp2"></address>
                            <a href=""></a>
                            <button type="button">열기</button>
                        </div>
                        <div class="map"></div>
                        <b class="kind" style="display:none;"></b>
                    </li>
                </ul>
            </div>
            <div class="no_results">
                <div>
                    <img src="/img/intro/branch/ico_alert.png" alt=""><br><br>
                    <span>검색된 정보가 없습니다.</span>
                </div>
            </div>
		</section>
    </main>
    <!-- /main -->
</template>



<script>
    export default {}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/intro/branch.scss';
</style>