<template>
<!-- main -->
<main id="container">

    <!-- 페이지명 -->
    <!-- <section id="pageName">
        <div class="inner">
            <h3 class="tit_section align-center"></h3>
            <p class="desc_section align-center"></p>
            <div class="list_sns">
                <a class="link_sns kakao share_link" href="javascript:void(0)"><span class="screen_out">카카오톡 공유하기</span></a>
            </div>  
        </div>
    </section> -->
    <!-- 페이지명 -->

    <!-- 글보기 -->
    <section id="boardView" class="section">

    <div class="num"></div>
        <div class="inner">
            <div class="board_wrap">
                <div class="board_view">
                    <div class="view_tit">
                        <p class="tit"></p>
                        <p class="date"></p>
                    </div>
                    <div class="view_cont"></div>
                    <div class="view_btn">
                        <a class="link_list" href="javascript:void(0)">목록</a>
                    </div>
                </div>
                <div class="board_preview">
                    <ul class="list_preview">
                        <li class="item_preview">
                            <a href="javascript">
                                <p class="cate"></p>
                                <p class="tit lineClamp"></p>
                                <p class="date"></p>
                            </a>
                        </li>
                        <li class="item_preview">
                            <a href="javascript">
                                <p class="cate"></p>
                                <p class="tit lineClamp"></p>
                                <p class="date"></p>
                            </a>
                        </li>
                    </ul>
                    <div class="preview_btn">
                        <a class="link_prev" href="javascript:void(0)">이전글<img src="" data-images-path="/image/common/prev04.png" alt="이전"></a>
                        <a class="link_next" href="javascript:void(0)">다음글<img src="" data-images-path="/image/common/next04.png" alt="다음"></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- //글보기 -->
</main>
<!-- /main -->
</template>



<script>
    export default {}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/board/board.scss';
</style>
