<template>
<!-- main -->
<main id="container" class="newScrollAction" data-name="webzine">
    <h1 class="screen_out">웹진</h1>

    <section id="webzine" class="section">
		<h2>
			<div class="inner">
				<strong>웹진</strong>
				<span>웹진으로 만나는 재무보좌관 이야기</span>
			</div>
		</h2>
		<div class="area_top">
			<div class="inner">
				<div class="thumb">
					<a target="_blank" class="open">
						<img src="" alt="" onError="this.src='https://via.placeholder.com/380x240/fff/fff?text=loading'">
					</a>
				</div>
				<div class="date_area">
					<div class="date_view">
						<i>2020</i>.<i>06</i>
					</div>
					<div class="date_select">
						<label>
							<select name="webzineDateYear" id="webzine_year">
								<!-- <option value="2021">2021년</option> -->
							</select>
							<u></u>
						</label>
						<label>
							<select name="webzineDateMonth" id="webzine_month">
								<!-- <option value="01">01월</option> -->
							</select>
							<u></u>
						</label>
					</div>
				</div>
			</div>
		</div>
		<div class="area_bottom">
			<div class="inner">
				<div class="content_view">
					<a target="_blank" class="open">
						<img src="" alt="" onError="this.src='https://via.placeholder.com/380x240/fff/fff?text=loading'">
					</a>
				</div>
				<div class="bottom">
					<a target="_blank" class="open">웹진 보기</a>
				</div>
			</div>
		</div>
	</section><!-- / #webzine -->    
</main>
<!-- /main -->
</template>



<script>
    export default {}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/promotion/webzine.scss';
</style>
