import { render, staticRenderFns } from "./recruitFa.vue?vue&type=template&id=a3f6a4c8&scoped=true&"
import script from "./recruitFa.vue?vue&type=script&lang=js&"
export * from "./recruitFa.vue?vue&type=script&lang=js&"
import style0 from "./recruitFa.vue?vue&type=style&index=0&id=a3f6a4c8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3f6a4c8",
  null
  
)

export default component.exports