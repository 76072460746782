<template>
<!-- main -->
<main id="container" data-name="news">
    <h1 class="screen_out">뉴스</h1>

    <section class="news_detail">
        <h3 class="d_tit"></h3>
        <span class="date"></span>
        <div class="contents"></div>
        <div class="btn_layout">
            <a href="/promotion/news">목록</a>
        </div>
        <div class="list">
            <div class="left">
                <strong></strong>
                <a href="" class="clamp3"></a>
                <em></em>
            </div>
            <div class="right">
                <strong></strong>
                <a href="" class="clamp3"></a>
                <em></em>
            </div>
        </div>
        <div class="btn_bottom_layout">
            <a href="" class="prev">이전글</a>
            <a href="" class="next">다음글</a>
        </div>
    </section>

    <!-- SNS Share -->
    <snsShare></snsShare>
    <!-- //SNS Share -->
</main>
<!-- /main -->
</template>



<script>
    export default {}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/promotion/news_detail.scss';
</style>
