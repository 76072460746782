<template>
    <main id="container" class="newScrollAction">
		<section id="visual">
			<p>피플라이프의 투명한 경영정보</p>
		</section>
		<section id="notify_list">
            <div class="inner">
                <div class="tit_box">
                    <h2 class="tit_section">경영공시</h2>
                    <p class="desc_section">피플라이프 경영의 투명성을 확대하기 위해<br> 내용을 공시하고 있습니다.</p>
                </div>
                <div class="board_list">
                    <p class="total_number">총 <strong>12</strong>건</p>
                    <ul>
                        <li class="item">
							<a href="javascript:void(0)" class="item_inner" target="_blank">
								<em data-text="title"></em>
								<div class="desc">
									<p data-text="create"></p>
									<span data-text="date"></span>
									<i></i>
								</div>
							</a>
						</li>
                    </ul>
					<button type="button" class="more">
						<i></i>
						더보기
					</button>
                </div>
				<div class="button_wrap">
                    <p>더 많은 공시정보는 <span>전자공시 시스템(DART)</span>을 통해<br> 확인 할 수 있습니다.</p>
                    <a href="https://dart.fss.or.kr/" target="_blank">Dart 바로가기</a>
                </div>
			</div>
        </section>
    </main>
</template>



<style scoped lang="scss">
    @import '@/assets/scss/intro/notify.scss';
</style>



<script>
    export default {}
</script>