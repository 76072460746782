<template>
<!-- main -->
<main id="container" class="newScrollAction">
    <h1 class="screen_out">기업컨설팅</h1>

    <!-- 메인비주얼 -->
    <section class="visual">
        <div class="txt_box">
            <em>기업컨설팅</em>
            <p>중소 사업자를 위한</p>
            <strong>지속 가능한 가이드</strong>
        </div>
    </section>
    <!-- //메인비주얼 -->

    <!-- 주요정보 -->
    <section class="information section">
        <h2 class="title"><img :src="imgPath+'information_title.png'" alt="clinic"></h2>
        <div class="exp">
            <div>
                <p class="img"><img :src="imgPath+'infomation_thumb.jpg'" alt=""></p>
                <p class="tit">경영리스크로 골치 아픈 순간!<br>중소기업 위기극복<br>파트너가 도와드리겠습니다.</p>
                <p class="txt">
                    중소 사업자는 예기치 못한 경영리스크로<br>
                    한순간에 쌓아온 모든 것을 잃을 수 있습니다. <br>
                    리스크 관리에서 사후관리까지 오랜 기간 시행착오를 <br>
                    겪으며 쌓아온 집약된 노하우로 중소기업에 꼭! <br>
                    필요한 솔루션을 제시해 드립니다.
                </p>
            </div>
        </div>
    </section>
    <!-- //주요정보 -->

    <!-- 차별화 서비스 -->
    <section id="differentiation" class="section">
        <div class="inner">
            <h3 class="tit_section">기업컨설팅의 차별화 된 서비스 <br>「 RISK Solution 」</h3>
            <p class="desc_section">중소기업 경영 시 발생할 수 있는 위험요소!<br> <strong>전속 자문 법인</strong>과 풍부한 컨설팅 경력을 통해 리스크를 최소화하는 솔루션을 제공합니다. </p>
            <ul class="list">
                <li class="isAppear80">
                    <i></i>
                    <strong><mark>경정청구를 통한 세금 환급</mark></strong>
                    <p>5년간 더 낸 세금 환급하는 서비스</p>
                </li>
                <li class="isAppear80">
                    <i></i>
                    <strong><mark>투자수익률</mark><br><mark>이익금환원 솔루션</mark></strong>
                    <p>법인과 개인의 소득 분산 등 활용</p>
                </li>
                <li class="isAppear80">
                    <i></i>
                    <strong><mark>경영효율화 핵심 전략</mark></strong>
                    <p>인사노무, 인증, 연구소, 특허 등 활용</p>
                </li>
                <li class="isAppear80">
                    <i></i>
                    <strong><mark>경영리스크 해결 및 관리</mark></strong>
                    <p>명의신탁주식, 가지급금, 가수금,<br> 임원의 순직 등 리스크 대비</p>
                </li>
                <li class="isAppear80">
                    <i></i>
                    <strong><mark>재무구조 분석</mark></strong>
                    <p>정관 제도정비, 재무구조 개선,<br> 기업 예비진단 등 체크</p>
                </li>
                <li class="isAppear80">
                    <i></i>
                    <strong><mark>가업승계와 효율적인 전락</mark></strong>
                    <p>2023년 승계요건 완화와<br> 공제액 상향 등 활용</p>
                </li>
                <li class="isAppear80">
                    <i></i>
                    <strong><mark>중소기업 M&amp;A</mark></strong>
                    <p>인수합병 활성화로 새로운<br> 성장 동력 확보</p>
                </li>
                <li class="isAppear80">
                    <i></i>
                    <strong><mark>사내근로복지기금</mark></strong>
                    <p>기업복지제도를 활용한<br> 근로자의 복지혜택 제공</p>
                </li>
            </ul>
        </div>
    </section>
    <!-- //차별화 서비스 -->    

    <!-- 회사연혁 -->
    <section id="history" class="section">
        <h3 class="tit_section"> 기업과 함께 동반 상승하는 착한 회사,<br> 신뢰할 수 있는 피플라이프 </h3>
        <p class="desc_section">대한민국 중소사업자와 동반 상승하는 피플라이프<br>5년, 10년, 20년 후에도 기업이 존속할 수 있도록 <br>지속적인 서비스를 제공합니다. </p>
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide null"></div>
                <div class="swiper-slide null"></div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2024</p>
                        <p class="desc">
                            M보험클리닉 신규 론칭<br>
                            보험클리닉 신규 광고<br>
                            프리미엄 브랜드 대상 수상<br>
                        </p>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2023</p>
                        <p class="desc">
                            한화그룹편입<br>
                            이브이케어와 MOU 체결<br>
                            보험클리닉 신규 광고(현빈)<br>
                            2023 만나 Show 행사 진행
                        </p>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2021</p>
                        <p class="desc">매출액 3,135억 ,<br>기업고객 99,335 ,<br>공중파 광고(현빈) <br>한국M&A거래소 업무제휴 </p>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2019</p>
                        <p class="desc">‘CEO클리닉’ 브랜드 런칭<br>공중파 광고(현빈) </p>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2018</p>
                        <p class="desc">매출 2,000억원 돌파<br>‘보험클리닉’ 런칭 </p>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2017</p>
                        <p class="desc">기업고객 40,000개 돌파<br>매출 1,400억 </p>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2016</p>
                        <p class="desc">매출 1,200억 초과</p>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2015</p>
                        <p class="desc">중소기업 CEO, 골프 클래식 개최,<br>기업고객 30,000개 </p>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2014</p>
                        <p class="desc">매경경영지원본부 론칭,<br>매경미디어그룹과 업무제휴 </p>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2013</p>
                        <p class="desc">GA 업계 최초<br>공중파 광고 (조인성) </p>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2012</p>
                        <p class="desc">매출 660억,<br>기업컨설팅 고객 9,000개 돌파</p>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2003</p>
                        <p class="desc">회사설립 </p>
                    </div>                    
                </div>
                <div class="swiper-slide null"></div>
            </div>
        </div>
    </section>
    <!-- //회사연혁 -->

    <!-- 수상경력 -->
    <section id="award" class="section">
        <img :src="imgPath+'award_bg.jpg'" alt="">
    </section>
    <!-- //수상경력 -->

    <!-- 전문가 네트워크 -->
    <networkTab />
    <!-- //전문가 네트워크 -->

    <!-- 컨설팅 흐름 -->
    <roadMap />
    <!-- //컨설팅 흐름 -->

    <!-- 브로슈어 -->
    <section class="brochure">
        <h3 class="design_title">
            <img class="bi" src="" data-images-path="/image/logo/ceoclinic_extension.svg" alt="">
            Brochure
            <p>
                <span>중소기업을 위한<br><strong>경영효율화 컨설팅</strong></span>
            </p>
        </h3>
        <p class="txt">기업 경영시 코칭이 필요한 이유를 지금 확인해 보세요</p>
        <div class="img"><img :src="imgPath+'brochure_book.png?v=231124'" alt="brochure"></div>
        <a class="button" href="" data-json-path="/pdf/brochure2024.pdf" target="_blank" >브로슈어 보기</a>
    </section>
    <!-- //브로슈어 -->

    <!-- 코칭서비스 -->
    <section id="coaching" class="section">
        <div class="inner">
            <div class="box_info isAppear80">
                <img class="img-fluid" :src="imgPath+'coaching_info_thumb.jpg'" alt="사진">
                <div class="txt_info">
                    <p class="tit">깊이 있는 안목, 폭넓은 전문성을 갖춘<br> <strong>경영효율화</strong> 컨설팅</p>
                    <p class="desc">마땅히 지켜야 할 법!<br> 위반하지 않고 법 테두리 안에서 최적의<br> <strong>‘자산관리 프로그램’</strong> 솔루션을 선택하는 것 입니다. </p>
                </div>
            </div>
        </div>
    </section>
    <!-- //코칭서비스 -->

   

    <!-- 전국 서비스 -->
    <section id="service" class="section">
        <h3 class="tit_section">전국 법인컨설팅 솔루션<br> 「 CEO CLINIC 」</h3>
        <div class="desc_section">
            <p>'CEO클리닉'은</p>
            <p>제휴된 여러 전문가 네트워크와 함께</p>
            <p><s>'중소기업 경영효율화 법인컨설팅'</s> 이라는</p>
            <p>새로운 분야를 개척함으로써 합리적인 전략과</p>
            <p>솔루션을 제시하고 경영 효율화 전략을 실행 제안합니다.</p>
        </div>
        <div class="map">
            <dl class="list_map seoul pos_left">
                <dt>
                    <em>서울</em>
                    <!-- <strong>46</strong> -->
                </dt>
            </dl>
            <dl class="list_map kangwon pos_right">
                <dt>
                    <em>강원</em>
                    <!-- <strong>4</strong> -->
                </dt>
            </dl>
            <dl class="list_map  gyeonggi pos_right">
                <dt>
                    <em>경기</em>
                    <!-- <strong>11</strong> -->
                </dt>
            </dl>
            <dl class="list_map chungchung pos_left">
                <dt>
                    <em>충청</em>
                    <!-- <strong>4</strong> -->
                </dt>
            </dl>
            <dl class="list_map jeolla pos_left">
                <dt>
                    <em>전라</em>
                    <!-- <strong>5</strong> -->
                </dt>
            </dl>
            <dl class="list_map gyeongsang pos_right">
                <dt>
                    <em>경상</em>
                    <!-- <strong>18</strong> -->
                </dt>
            </dl>
            <dl class="list_map jeju pos_left">
                <dt>
                    <em>제주</em>
                    <!-- <strong>1</strong> -->
                </dt>
            </dl>
            <i class="cirlce seoul"></i>
            <i class="cirlce kangwon"></i>
            <i class="cirlce gyeonggi"></i>
            <i class="cirlce chungchung"></i>
            <i class="cirlce jeolla"></i>
            <i class="cirlce gyeongsang"></i>
            <i class="cirlce jeju"></i>
            <img class="bg img-fluid" :src="imgPath+'service_map_bg.png'" alt="">
            <img class="bg_line img-fluid" :src="imgPath+'service_map_bg_line_v1.png'" alt="">
        </div>
        <!-- <p class="count">총<span class="num">89</span>개 사업단</p>
        <p class="source">2024년 5월 사업단 현황</p> -->
    </section>
    <!-- //전국 서비스 -->

    <!-- 어플리케이션 -->
    <!-- <section id="ceoapp" class="section">
        <div class="inner">
            <h3 class="tit_section">내 손에서 이뤄지는 CEO위기극복<br> 서비스「 CEO CLINIC 」</h3>
            <p class="desc_section">지속 가능 경영을 위한 1:1 전문가 상담 서비스</p>
            <a class="link_more" href="//ceo-clinic.co.kr/h/5AQh7kHWen" target="_blank"><span>CEO클리닉 모바일 웹 바로가기 </span><img src="" data-images-path="/image/svg/arr_right01.svg" alt="앞으로"></a>            
            <div class="list_device">
                <img class="device device1" :src="imgPath+'ceoapp_device1.png'" alt="어플 캡쳐">
                <img class="device device2" :src="imgPath+'ceoapp_device2.png'" alt="어플 캡쳐">
            </div>
        </div>
    </section> -->
    <!-- //어플리케이션 -->

    <!-- 상담 프로세스 -->
    <section id="process">
        <h3 class="title">법인컨설팅 상담 프로세스</h3>
        <ul class="list_process">
            <li class="item_process isAppear80">
                <i class="ico_bullet"></i>
                <p class="tit">STEP 1.<em>상담 신청</em></p>
                <p class="desc">원하시는 상담 분야를 선택하시면 담당자가 확인한 후 연락을 드립니다.</p>
                <img class="thumb" :src="imgPath+'process_thumb1.jpg'" alt="">
            </li>
            <li class="item_process isAppear80">
                <i class="ico_bullet"></i>
                <p class="tit">STEP 2.<em>재무보좌관 지정 </em></p>
                <p class="desc">각 분야별로 특화된 전문가가 고객님의 기업 상황에 맞는 분야로 지정됩니다.</p>
                <img class="thumb" :src="imgPath+'process_thumb2.jpg'" alt="">
            </li>
            <li class="item_process isAppear80">
                <i class="ico_bullet"></i>
                <p class="tit">STEP 3.<em>기업검진</em></p>
                <p class="desc">기업 정보를 분석한 빅데이터를 기반으로 기업을 진단합니다.</p>
                <img class="thumb" :src="imgPath+'process_thumb3.jpg'" alt="">
            </li>
            <li class="item_process isAppear80">
                <i class="ico_bullet"></i>
                <p class="tit">STEP 4.<em>솔루션제안 </em></p>
                <p class="desc">기업의 리스크를 최소화하고 주주들의 성장까지 책임질 수 있도록 토탈 솔루션을 제공합니다.</p>
                <img class="thumb" :src="imgPath+'process_thumb4.jpg'" alt="">
            </li>
            <li class="item_process isAppear80">
                <i class="ico_bullet"></i>
                <p class="tit">STEP 5.<em>재무보좌관 지정</em></p>
                <p class="desc">적절한 솔루션에 따라 세무·법무·노무·부동산·금융·M&A 등 각 분야별 전문가가 상담합니다.</p>
                <img class="thumb" :src="imgPath+'process_thumb5.jpg'" alt="">
            </li>
            <li class="item_process isAppear80">
                <i class="ico_bullet"></i>
                <p class="tit">STEP 6.<em>솔루션 실행</em></p>
                <p class="desc">구성된 전문가가 개별 기업에 꼭 맞는 맞춤형 솔루션을 실행합니다.</p>
                <img class="thumb" :src="imgPath+'process_thumb6.jpg'" alt="">
            </li>
            <li class="item_process isAppear80">
                <i class="ico_bullet"></i>
                <p class="tit">STEP 7.<em>사후관리</em></p>
                <p class="desc">실행 후 지속적인 관리로 기업의 경영 리스크를 최소화하는 솔루션을 제공합니다.</p>
                <img class="thumb" :src="imgPath+'process_thumb7.jpg'" alt="">
            </li>
        </ul>
    </section>
    <!-- //상담 프로세스 -->

    <!-- 상담신청  -->
    <section id="freeConsult" class="section">
        <div class="inner">
            <h3 class="tit_section">기업컨설팅 신청하기</h3>
            <p class="desc_section">중소기업을 경영할 때에는<br> 여러 가지 체감할 수 있는 위험 요소가 산재합니다.<br> 풍부한 컨설팅 경험이 있는 재무 보좌관이<br> 경영 리스크를 최소화할 수 있는 솔루션을 제공합니다. </p>
            <div class="render_form"></div>
            <li class="row row_type row_css clearfix" data-row="type" data-check="true" data-check-pass="false" data-check-comment="상담분야를 선택해주세요" data-inbound-prop="codeId">
                <label class="select_wrap">
                    <select name="consult" class="openSans consult select_css">
                        <option value="">상담분야 선택</option>
                        <option value="241">세무</option>
                        <option value="242">노무</option>
                        <option value="243">법률</option>
                        <option value="244">부동산</option>
                        <option value="245">금융</option>
                        <option value="229">기타</option>
                    </select>
                    <u class="select_arrow theme_border_left_color theme_border_bottom_color"></u>
                </label>
            </li>
        </div>
    </section>
    <!-- //상담신청  -->

    <!-- 안내사항 -->
    <section id="about" class="section">
        <div class="inner">
            <p class="desc_section">명실상부, 법인컨설팅의 명가!</p>
            <h3 class="tit_section">피플라이프와 함께 하세요!<br><strong>다음 성공의 주인공은 당신입니다.</strong></h3>
            <a class="link_about" href="/recruit/recruitCorp"><span>법인영업 인재채용 바로가기</span><img src="" data-images-path="/image/svg/arr_right01.svg" alt="앞으로"></a>
            <img class="model" :src="imgPath+'about_model.png'" alt="모델">
        </div>
    </section>
    <!-- //안내사항 -->
</main>
<!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/sales/corporation/'
        }
    }
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/sales/corporation.scss';
</style>
