<template>
<!-- main -->
<main id="container" class="newScrollAction" data-name="promotion">
    <h1 class="screen_out">홍보자료</h1>

    <section>
        <h3 class="title">홍보자료<em>피플라이프를 다양한 자료를 만나실 수 있습니다.</em> </h3>
    </section>
    
    <section class="visual">
        <h4>
            <strong>PEOPLELIFE</strong> Room<br>
            고객과 가까운 기업, <strong>피플라이프</strong>
        </h4>
        <p class="description">
            공중파 TV CF, 케이블 광고와 더불어 유튜브, SNS 등<br> 
            다양한 채널을 통해 피플라이프를 만나실 수 있습니다. 
        </p>
        <div class="webzin">
            <a target="_blank">
                <span>
                    <img src="" alt="">
                </span>
                <em>2022.12</em>
            </a>
        </div>
        <div class="tvc">
            <button class="videoLayer" data-src="/video/tvcf/ceoclinic/2019_season2.mp4" data-title="" data-type="" type="button" title="영상재생">
                <img class="thumb" src="" data-images-path="/video/tvcf/ceoclinic/2019_season2.jpg" alt="스크린샷">
            </button>
            <p class="tit">CEO클리닉 TVC 광고 &lt;위기극복&gt;편</p>
        </div>
        <div class="etc">
            <div class="dream type1">
                <p>
                    <span>
                        <img src="/img/promotion/promotion/img_thedreamtype1.jpg" alt="">
                    </span>
                </p>
                <em class="tit">보클 처방전</em>
            </div>
            <div class="dream type2">
                <p>
                    <img src="/img/promotion/promotion/img_thedream.jpg" alt="">
                </p>
                <em class="tit">피플라이프 다드림쇼</em>
            </div>
        </div>
    </section>
    <section class="tvadvertising">
        <ul class="list">
            <li>
                <button type="button" class="video">
                    <i class="onair">ON-AIR</i>
                    <div class="youtube"></div>
                </button>
                <div class="info">
                    <strong class="clamp"></strong>
                    <span class="clamp"></span>
                </div>
            </li>
        </ul>
    </section>
    <section class="dramaadvertising">
        <ul class="list">
            <li>
                <button type="button" class="video">
                    <div class="youtube"></div>
                </button>
                <div class="info">
                    <strong class="clamp"></strong>
                    <span class="clamp"></span>
                </div>
            </li>
        </ul>
        <button type="button" class="more">
            <i></i>
            더보기
        </button>
    </section>
    <section class="peoplelife_sns">
        <h3 class="title">
            # PEOPLELIFE SNS
        </h3>
        <p class="description">끊임없이 고객과 소통하는 우리는 피플라이프입니다.</p>
        <ul>
            <li>
                <span class="img"><img src="/img/promotion/promotion/img_sns_list1.png" alt=""></span>
                <strong class="logo"><img src="/img/promotion/promotion/logo_sns_list1.png" alt="피플라이프"></strong>
                <div class="hash">
                    <span># 금융솔루션</span>
                    <span># 혁신의 아이콘</span>
                    <span># 금융상품판매 전문 플랫폼</span>
                </div>
                <div class="social">
                    <a href="https://www.youtube.com/channel/UCRbs93BBQZa2SZUufB4Jepg" target="_blank" class="y">유튜브 바로가기</a>
                    <a href="https://blog.naver.com/peoplelifepr" target="_blank" class="b">블로그 바로가기</a>
                </div>
            </li>
            <li>
                <span class="img"><img src="/img/promotion/promotion/img_sns_list2.png" alt=""></span>
                <strong class="logo"><img src="/img/promotion/promotion/logo_sns_list2.png" alt="보험클리닉"></strong>
                <div class="hash">
                    <span># 보장분석</span>
                    <span># 보험리모델링</span>
                    <span># 보험을 바로잡다</span>
                </div>
                <div class="social">
                    <a href="https://www.youtube.com/channel/UCzcc4DNIpNngewyH0Lt1tpg" target="_blank" class="y">유튜브 바로가기</a>
                    <a href="https://www.facebook.com/bohumclinic" target="_blank" class="f">페이스북 바로가기</a>
                    <a href="https://www.instagram.com/bohum_clinic" target="_blank" class="i">인스타그램 바로가기</a>
                    <a href="https://blog.naver.com/bohum_clinic" target="_blank" class="b">블로그 바로가기</a>
                </div>
            </li>
            <li>
                <span class="img"><img src="/img/promotion/promotion/img_sns_list3.png" alt=""></span>
                <strong class="logo"><img src="/img/promotion/promotion/logo_sns_list3.png" alt="CEO클리닉"></strong>
                <div class="hash">
                    <span># 중소기업</span>
                    <span># 위기극복 파트너</span>
                    <span># 리스크 관리 컨설팅</span>
                </div>
                <div class="social">
                    <a href="https://www.facebook.com/ceoclinickr/" target="_blank" class="f">페이스북 바로가기</a>
                    <a href="https://www.youtube.com/channel/UCv127K0IeyDsPttFEw20blQ" target="_blank" class="y">유튜브 바로가기</a>
                </div>
            </li>
        </ul>
    </section>
    <section class="brochure">
        <h3 class="design_title"><strong>PEOPLELIFE</strong> Brochure <span>재무보좌관 피플라이프</span></h3>
        <p class="txt"> 개인과 기업고객 모두에게 기분 좋은 금융 경험을 제공하는 회사. 바로 당신만의, 당신을 위한  ‘재무 보좌관’ 피플라이프입니다. </p>
        <div class="img"><img src="/img/intro/company/pic_brochure.png?v=230113" alt="brochure"></div>
        <a class="button" href="" data-images-path="/pdf/peoplelife_brochure/2023.pdf" target="_blank">자세히보기</a>
    </section>
</main>
<!-- /main -->
</template>



<script>
    export default {}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/promotion/promotion.scss';
</style>
