<template>
    <section id="networkTab" class="section" data-tab-index="1">
        <h3 class="tit_section">전속 자문을 통한 One-Stop 서비스</h3>
        <p class="desc_section">법인 경영의 리스크를 최소화하고 기업과 주주들의<br> 성장에 도움이 될 수 있도록 집중 관리해 드립니다.</p>
        <div class="list_tab hidden">
            <button class="btn_tab" type="button">전문가 네트워크</button>
            <button class="btn_tab" type="button">전속 네트워크</button>
            <button class="btn_tab" type="button">제휴 네트워크</button>
        </div>
        <ul class="list_illust">
            <li class="item_illust">
                <img src="이미지경로" alt="프로필">
                <p class="tit">내용</p>
            </li>
        </ul>
    </section>
</template>



<script>
import $ from 'jquery'

export default {
    name: 'networkTab',
    data() {
        return {
            imgPath: '/img/common/contents/networkTab/'
        }
    },
    mounted() {
        $(function(){ // DOCUMENT READY...
        /*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */(function(){



            /* 리스트 생성 */
            var $wrap = $('#networkTab');
            var $list = $wrap.find('.list_illust');
            var $item = $wrap.find('.item_illust');
            var $item_copied = $item.detach();

            var item = [
                {
                    'name': 'tsi',
                    'tit': '세무법인<br>세종TSI'
                },{
                    'name': 'gaius',
                    'tit': '가이우스<br>법률사무소'
                },{
                    'name': 'seohyun',
                    'tit': '노무법인<br>서현'
                },{
                    'name': 'iksun',
                    'tit': '노무법인<br>익선'
                },{
                    'name': 'koreamna',
                    'tit': '한국<br>M&A거래소'
                },{
                    'name': 'ipo',
                    'tit': 'IPO·투자유치<br>·M&A자문'
                },{
                    'name': 'estate',
                    'tit': '부동산<br>중개'
                },{
                    'name': 'tqcsi',
                    'tit': 'DPBS'
                },
            ]

            item.forEach(function(item, i) {
                var _li = $item_copied.clone();

                _li.attr('data-name', item.name);
                _li.find('img').attr('src', '/img/common/contents/networkTab/'+(i+1)+'.jpg?v=231010');
                _li.find('.tit').html(item.tit);
                _li.prependTo($list);
            });



        })();/*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */(function(){



            var $wrap = $('#networkTab');            
            var $tabBtn = $wrap.find('.btn_tab');

            $tabBtn.on('click', function(){
                var $this = $(this);
                var index = $this.index() + 1; 
                
                $wrap.attr('data-tab-index',index);                
            });
            


        })();/*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */});// DOCUMENT READY...
    },
}
</script>



<style lang="scss" scoped>
    $imgPath : "/img/common/contents/networkTab/";

    #networkTab {
        .tit_section {
            padding-top:10vw;font-size:6.4vw;font-weight:500;text-align:center;letter-spacing:-0.07em;
        }
        .desc_section {
            padding:7vw 0 6.7vw;font-size:4vw;color:#666666;text-align:center;;
        }
    }

    #networkTab .list_tab {
        display:flex;justify-content:space-between;margin-bottom:6.7vw;padding:2.4vw 4.4444vw;border-top:1px soild #f0f0f0;border-bottom:1px soild #f0f0f0;background-color:#f8f9fb;
        .btn_tab {
            height:6.7vw;padding:0 4vw;border-radius:2em;font-size:4vw;color:#89898f;line-height:1em;
        }
    }

    #networkTab {
        @for $i from 1 through 3 {
            &[data-tab-index="#{$i}"] {
                .btn_tab:nth-child(#{$i}) {
                    color:#fff;font-weight:500;background-color:#0da8ff;
                }
            }
        }
    }

    #networkTab .list_illust {
        position:relative;transition:height 0.4s ease-in-out;
        .item_illust {
            position:absolute;transition:all 0.4s ease-in-out;
            img {
                width:26.67vw;height:26.67vw;border:1px solid #e7e7e7;border-radius:100%;transform:scale(0.6);transition:none;
            }
            .tit {
                position:absolute;bottom:-9vw;left:50%;width:100%;;height:8vw;font-size:3.2vw;color:#666666;text-align:center;line-height:1.3em;letter-spacing:-0.06em;transform:translateX(-50%);
            }
        }
    }

    #networkTab {
        &[data-tab-index="1"] .list_illust {
            height:85.7vw;
        }
        &[data-tab-index="2"] .list_illust {
            height:90.67vw;            
        }
        &[data-tab-index="3"] .list_illust {
            height:90.67vw;
        }
    }

    #networkTab {
        $left3-1 : 5vw;
        $left3-2 : 37vw;
        $left3-3 : 68vw;
        $left4-1 : 5vw;
        $left4-2 : 27vw;
        $left4-3 : 47vw;
        $left4-4 : 68vw;
        $topCenter: 45.73vw;
        $value:
        //   name           top1        left1        opacity1   top2             left2         opacity    top3             left3         opacity3
            'tsi'           0           $left4-1        1       0                $left3-1         1       0                10vw          0,
            'gaius'         0           $left4-2        1       0                $left3-2         1       0                30vw          0,
            'seohyun'       0           $left4-3        1       0                $left3-3         1       0                50vw          0,
            'iksun'         0           $left4-4        1       0                10vw             0       0                $left4-1      1,
            'koreamna'      43.6vw      $left4-1        1       0                30vw             0       0                $left4-2      1,
            'ipo'           43.6vw      $left4-2        1       0                50vw             0       0                $left4-3      1,
            'estate'        43.6vw      $left4-3        1       $topCenter       $left3-1         1       0                10vw          0,
            'tqcsi'         43.6vw      $left4-4        1       $topCenter       $left3-2         1       0                30vw          0,
        ;
        @each $name, $top1, $left1 ,$opacity1, $top2, $left2, $opacity2, $top3, $left3, $opacity3 in $value {
            &[data-tab-index="1"] {
                .item_illust[data-name="#{$name}"] {
                    top:#{$top1};left:#{$left1};opacity:#{$opacity1};
                }
            }
            &[data-tab-index="2"] {
                .item_illust[data-name="#{$name}"] {
                    top:#{$top2};left:#{$left2};opacity:#{$opacity2};
                }
            }
            &[data-tab-index="3"] {
                .item_illust[data-name="#{$name}"] {
                    top:#{$top3};left:#{$left3};opacity:#{$opacity3};
                }
            }
        }
    }

    #networkTab.appear .item_illust {
        img {
            transform:scale(1);transition:all 0.6s 0.3s ease-in-out;        
        }
    }
</style>