<template>
<!-- main -->
<main id="container" data-name="complete">
    <h1 class="screen_out">입사지원완료</h1>

    <div class="complate-container">
        <div class="complete_logo_wrap">
            <h1 class="logo"><img src="/img/common/logo/logo-peoplelife-white.png" alt="피플라이프"></h1>
        </div>
        <div class="complete__contents">
            <div class="complete_cont">
                <img src="/img/recruit/recruitComplete/cmm-recruit-complete.png" class="img" alt="">
                <h2>당신의 꿈, 그 실현을 위해<br>피플라이프가 함께 하겠습니다.</h2>
                <p class="txt">입사지원 신청이 완료되었습니다.<br>인사담당자가 확인 후<br>기재해 주신 연락처로 전화드리겠습니다.<br>감사합니다.</p>
                <div class="complete_btn_area">
                    <a href="javascript:history.back()"><span class="txt"></span>이전화면으로 이동</a>
                </div>
            </div>
            <!-- <div class="complete_jobform">
                <div class="complete_inform">
                    <h5>입사지원서를 제출하지 않으셨다면,<br><span>입사지원서를 메일 발송해주시기 바랍니다.</span></h5>
                    <p class="complete_desc">
                        <span>※ 다른 양식의 입사지원서로도 지원 가능합니다. <br>(잡코리아, 사람인 이력서도 제출 가능)</span>
                    </p>
                    <p class="complete_desc">
                        <span>※ 입사지원서 다운로드시 PC에서 입력 후 제출을 권장합니다. <br>(일부 모바일기기에서 정상적으로 열리지 않을 수 있습니다.)</span>
                    </p>
                    <ul>
                        <li><a href="/file/EFA상담매니저_자사양식_이력서.docx">입사지원서 다운로드</a></li>
                        <li><a href="mailto:efa@peoplelife.co.kr">입사지원서 이메일 제출</a></li>
                    </ul>
                    <p class="complete_ps">접수 이메일 : efa@peoplelife.co.kr</span></p>
                </div>
            </div> -->
        </div>

    </div>
</main>
<!-- /main -->
</template>



<style scoped lang="scss">
    .complate-container {
        position:fixed; z-index: 50;  overflow:auto;width:100%;height:100%;max-height:none;background: url(/img/recruit/recruitComplete/bg-recruit-complete.jpg)0 0 no-repeat;background-size:cover;
        &::before {
            content:'';position:absolute;top:0;left:0;width:100%;height:100%;background-color: rgba(0,0,0,.75);
        }
        .complete_logo_wrap {
            position:absolute;top:0;left:0;z-index:1;
            .logo {
                margin:14% 0 0 17%;
            }
        }
        .complete__contents {
            position:relative;z-index:1;width:100%;text-align:center;
            h2 {
                margin:5vw 0 3vw;font-size:21px;font-weight:700;line-height:1.5;color:#fff;
            }
            .complete_cont {
                margin-top:35vw;font-size:16px;
                p {
                    margin-bottom:5px;color:#fff;
                }
                .complete_btn_area {
                    a {
                        display:inline-block;width:38vw;height:12vw;margin-top:6vw;border:0.26666vw solid #fff;font-size:3.4vw;color:#fff;text-align:center;line-height:12vw;
                    }
                }
            }
            .btn {
                margin-top:3.8vw;padding-left:15px;padding-right:15px;
            }
            .complete_jobform {
                margin-top:15vw;width:100%;
                .complete_inform {
                    padding: 7vw 4vw 5vw;width: 100%;height:100%;color:#fff;background-color: #3a478f;letter-spacing: -0.025em;
                    h5 {
                        font-size:4.6vw;line-height:1.5;margin:0;
                    }
                    .complete_desc {
                        line-height: 1.3;
                        span {
                            color:#bec8ff; font-size:3.5vw
                        }
                    }
                    .complete_ps {
                        font-size:3.6vw;margin-top: 5vw;margin-bottom: 0;
                    }
                    ul {
                        padding:0; margin-top: 3vw;
                        li {
                            position: relative; width: 100%;height: 13vw; line-height: 12vw; border:1px solid #fff; text-align: center;  list-style: none;
                            &::before {
                                content: ''; position: absolute;top:50%; left:6vw; width: 6.5vw; height: 5vw; transform: translateY(-50%);
                            }
                            &:first-child::before {
                                background: url(/mg/common/ico/icon_download.png)0 0 no-repeat; background-size: contain;
                            }
                            &:last-child::before {
                                background: url(/mg/common/ico/icon_malito.png)0 0 no-repeat; background-size: contain;
                            }
                            a {
                                color:#fff; font-size:4.7vw; font-weight: 700;
                            }
                        }
                        li+li {
                            margin-top: 3.9vw;
                        }
                    }
                }
            }
        }

    }
</style>



<script>
import $ from 'jquery'

export default {
    mounted() {
        $(function(){ // DOCUMENT READY...
        /*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */(function(){


            $('#wrap').css('padding-top',0);
            $('header').hide();
            $('footer').hide();


        })();/*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */});// DOCUMENT READY...
    }
}
</script>