<template>
    <!-- header -->
    <header style="display: none;">
        <div class="inner">
            <h1><a href="/">logo</a></h1>
            <h2 class="sub_title"></h2>
            <button class="btn_menu">메뉴</button>
            <!-- global navigation bar -->
            <aside>
                <div class="content">
                    <section class="title marginNone">
                        <h3>
                            <strong>고객분들에게 딱 맞는</strong><br>
                            <strong>최적의 솔루션</strong>을 찾아드립니다.
                        </h3>
                        <nav class="consult_link">
                            <a href="/brand/ceo?freeConsult=indi">개인 상담신청<i></i></a>
                            <a href="/brand/ceo?freeConsult=corp">법인 상담신청<i></i></a>
                        </nav>
                    </section>
                    <nav class="list">
                    <ul class="depth_1">
                        <li>
                            <p>피플라이프</p>
                            <ul class="depth_2">
                                <li><a href="/intro/company">회사소개</a></li>
                                <li><a href="/intro/history">연혁</a></li>
                                <li><a href="/intro/network">전문가네트워크</a></li>
                                <li><a href="/intro/branch">사업단찾기</a></li>
                                <li><a href="/intro/social">사회공헌</a></li>
                                <li><a href="/intro/notify">경영공시</a></li>
                                <li><a href="/intro/finance">재무현황공시</a></li>
                            </ul>
                        </li>
                        <li>
                            <p>영업채널</p>
                            <ul class="depth_2">
                                <li><a href="/sales/corporation">법인컨설팅</a></li>
                                <li><a href="/sales/fa">개인영업(FA)</a></li>
                                <li><a href="/sales/otc">보험클리닉</a></li>
                                <li><a href="/sales/tfa">TM영업(TFA)</a></li>
                            </ul>
                        </li>
                        <li>
                            <p>브랜드</p>
                            <ul class="depth_2">
                                <li><a href="/brand/bohum">보험클리닉</a></li>
                                <li><a href="/brand/ceo">CEO클리닉</a></li>
                            </ul>
                        </li>
                        <li>
                            <p>홍보센터</p>
                            <ul class="depth_2">
                                <li><a href="/promotion/news">뉴스</a></li>
                                <li><a href="/promotion/webzine">웹진</a></li>
                                <li><a href="/promotion/promotion">홍보자료</a></li>
                                <li><a href="/promotion/inside">인사이드</a></li>
                            </ul>
                        </li>
                        <li>
                            <p>인재채용</p>
                            <ul class="depth_2">
                                <li><a href="/recruit/recruitInfo">채용정보</a></li>
                                <li><a href="/recruit/recruitCorp">법인컨설팅 채용</a></li>
                                <li><a href="/recruit/recruitFa">FA 채용</a></li>
                                <li><a href="/recruit/recruitBohum">보험클리닉 채용</a></li>
                            </ul>
                        </li>
                    </ul>
                    </nav>
                    <div class="header_foot">
                        <a class="clearfix" href="tel:02-6906-9114" onclick="_LA.EVT('3674')">
                            <div class="float-left">
                                <img src="/img/common/new/icon_call.png" alt="전화 02-6906-9114">
                            </div>
                            <div class="float-right">
                                <p>피플라이프 대표전화</p>
                                <strong>02-6906-9114</strong>
                            </div>
                        </a>
                    </div>
                    <button type="button" class="btnClose" data-target="#wrap" data-focus="">
                        <img src="/img/common/new/btnClose.png" alt="닫기">
                    </button>
                </div>
            </aside>
            <!-- //global navigation bar -->

            <!-- LNB -->
            <div id="twodeps">
                <div class="tab_contents">
                    <div class="tab" data-class="intro">
                        <ul>
                            <li><a href="/intro/company" data-name="company">회사소개</a></li>
                            <li><a href="/intro/history"  data-name="history">연혁</a></li>
                            <li><a href="/intro/network" data-name="network">전문가네트워크</a></li>
                            <li><a href="/intro/branch" data-name="branch">사업단찾기</a></li>
                            <li><a href="/intro/social" data-name="social">사회공헌</a></li>
                            <li><a href="/intro/notify" data-name="notify">경영공시</a></li>
                            <li><a href="/intro/finance" data-name="finance">재무현황공시</a></li>
                        </ul>
                    </div>
                    <div class="tab" data-class="sales">
                        <ul>
                            <li><a href="/sales/corporation" data-name="corporation">법인컨설팅</a></li>
                            <li><a href="/sales/fa" data-name="fa">개인영업(FA)</a></li>
                            <li><a href="/sales/otc" data-name="otc">보험클리닉</a></li>
                            <li><a href="/sales/tfa" data-name="tfa">TM영업(TFA)</a></li>
                        </ul>
                    </div>
                    <div class="tab" data-class="brand">
                        <ul class="col col2">
                            <li><a href="/brand/bohum" data-name="bohum">보험클리닉</a></li>
                            <li><a href="/brand/ceo" data-name="ceo">CEO클리닉</a></li>
                        </ul>
                    </div>
                    <div class="tab" data-class="promotion">
                        <ul class="col col4">
                            <li><a href="/promotion/news" data-name="news">뉴스</a></li>
                            <li><a href="/promotion/webzine" data-name="webzine">웹진</a></li>
                            <li><a href="/promotion/promotion" data-name="promotion">홍보자료</a></li>
                            <li><a href="/promotion/inside" data-name="inside">인사이드</a></li>
                        </ul>
                    </div>
                    <div class="tab" data-class="recruit">
                        <ul>
                            <li><a href="/recruit/recruitInfo" data-name="recruitInfo">채용정보</a></li>
                            <li><a href="/recruit/recruitCorp" data-name="recruitCorp">법인컨설팅 채용</a></li>
                            <li><a href="/recruit/recruitFa" data-name="recruitFa">FA 채용</a></li>
                            <li><a href="/recruit/recruitBohum" data-name="recruitBohum">보험클리닉 채용</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- //LNB -->
        </div>
    </header>
    <!-- // header -->
</template>


<style lang="scss">
    /* HEADER */
    header {
        position: fixed; width: 100%; z-index: 40; top: 0; left: 0; right: 0; padding: 3vw 0; height: 14.184vw; letter-spacing: -0.05em; border-bottom: 1px solid #ccc; background-color: #fff; transition: background-color 0.4s ease-in-out;
        h1, h2, h3, h4, h5, h6 {
            font-size: inherit; font-weight: inherit; margin: 0; padding: 0;
        }
        p {
            margin-bottom: 0;
        }
        h1 {
            position: absolute; top: 0; left: 4.4444vw; width: 8.4vw; height: 8vw;
            a {
                display: block; width: 100%; height: 100%; text-indent: -9999px; background-image: url(/img/common/new/icon_logo.png); background-size: 100%;
            }
        }
        .btn_menu {
            overflow: hidden; position: absolute; top: 50%; right: 4.4444vw; width: 5.833333333vw; height: 4.5vw; text-indent: -9999px; background: url(/img/common/new/ico_bar_black.png) left top no-repeat; background-size: 5.8vw; transform: translateY(-50%);
        }
        .sub_title {
            display: block; height: 8.5vw; line-height: 8.5vw; font-weight: 500; font-size: 4.8vw; text-align: center;
        }
        aside {
            overflow-y: auto; position: fixed; z-index: 30; top: 0; right: -100vw; margin: 0; width: 100vw; height: 100%; background-color: #fff; transition: 0.4s cubic-bezier(0.24, 0.29, 0.34, 1.04);
            .content {
                position: absolute; width: 100%; height: 100%;
                button.btnClose {
                    position: absolute; top: 3.5vw; right: 3.5vw; width: 6vw; height: 6vw;
                }
            }
            .title {
                padding: 13% 0 0 29%; width: 100%; height: 44.13334vw; background-image: url(/img/common/new/haeder_aside.png); background-size: 100%;
                h3 {
                    color: #fff; font-size: 1.315em; font-weight: 300;
                }
            }
            .consult_link {
                margin-top: 6%; color: #fff; font-size: 1.096em;
                a {
                    position: relative; padding-right: 10%; display: inline-block; transform: skewX(-8deg);
                    &:after {
                        content: ''; position: absolute; bottom: 20%; right: 5%; width: 4.6vw; height: 2.5334vw; background-image: url(/img/common/new/rightarrow.png); background-size: contain;
                    }
                    + a {
                        margin-left: 6%;
                        &::before {
                            content: ''; position: absolute; top: 50%; left: 0; width: 1px; height: 50%; margin-left: -12%; background-color: #fff; opacity: 0.2; transform: translateY(-50%) skewX(8deg);
                        }
                    }
                }
            }
            .list {
                font-size: 1.0971em; min-height: 97.66vw;
                ul li {
                    color: #020219; width: 100%;
                    &:active{background-color: rgba(0,0,0,0.08);}
                    a {
                        display: block; width: 100%;
                    }
                }

                .depth_1 > li {
                    + li {
                        border-top: 0.2667vw solid #d8d8d8;
                    }
                    &:last-child {
                        border-bottom: 0.2667vw solid #d8d8d8;
                    }
                    > p {
                        position: relative;
                        &::after {
                            content: ''; position: absolute; top: 50%; right: 7.7334vw; width: 2.1334vw; height: 1.3334vw; background-image: url(/img/common/new/deptharrow.png); background-size: contain; transform: translateY(-50%) rotate(180deg); transition: transform 0.2s ease-in-out;
                        }
                    }
                    &.active > p::after {
                        transition: transform 0.2s ease-in-out; transform: rotate(0deg);
                    }
                    > p {
                        line-height: 12vw; font-weight: 700; padding-left: 7.7334vw;
                    }
                }
                .depth_2 {
                    display: none; background-color: #f4f5f8;
                    > li {
                        border-top: 0.2667vw solid #d8d8d8;
                        > a {
                            line-height: 12vw; padding-left: 13.3334vw;
                        }
                    }
                }
                .depth_3 > li {
                    border-top: 0.2667vw solid #d8d8d8;
                    > a {
                        line-height: 12vw; padding-left: 18.9334vw;
                    }
                }
            }
            .header_foot {
                width: 100%; height: auto; padding: 3.5% 6%;
                > a {
                    display: block; background-color: #f6f6f6; border-radius: 1.3334vw; padding: 5% 19%;
                }
                .float-left:nth-child(1) {
                    width: 12.2667vw;
                }
                .float-right {
                    p {
                        font-size: 1.0241em; line-height: 1.3;
                    }
                    strong {
                        font-family: 'Roboto', sans-serif; color: #4f91ff; font-size: 1.6094em; letter-spacing: 0;
                    }
                }
            }
        }
    }

    /* HEADER - 메인화면 */
    .mainWrap header {
        border-bottom: 0; background-color: transparent;
        &.active {
            transition: background-color 0.4s ease-in-out; background-color: rgba(0, 0, 0, 0.75);
        }
        h1 {
            width: 26.6667vw; height: 8vw;
            a {
                background-image: url(/img/logo.png); background-size: 100%;
            }
        }
        .btn_menu {
            background: url(/img/common/new/ico_bar_white.png) left top no-repeat; background-size: 5.8vw;
        }
        .sub_title {
            display: none;
        }
    }

    /* HEADER - 햄버거메뉴 toggle > #wrap.addClass('opened') */
    .opened aside {
        right: 0; background-color: #fff;
    }

    /* LNB */
    #twodeps {
        display: none;z-index:20; position: fixed; top: 14.184vw; left: 0; width: 100%; border-bottom: 0.1333vw solid #ccc; background-color: #fff;
        .tab_contents {
            height: 12vw; line-height: 12vw;
            .tab {
                display: none; overflow: hidden;
                ul {
                    overflow-x:auto;display:flex;padding:0 4.4444vw;white-space:nowrap;
                    &.col {
                        padding:0;
                        @for $i from 1 through 4 {
                            &.col#{$i} {
                                li {
                                    width:#{100 / $i * 1%};padding-right:0;text-align:center;
                                }
                            }
                        }
                    }
                    li {
                        padding-right: 5.3333vw;
                        a {
                            display: block; font-size: 4vw;
                            &.on {
                                line-height: 11vw; color: #3b70fe; border-top: 0.5333vw solid #3b70fe;
                            }
                        }
                    }
                }
                &.active {
                    display: block;
                }
            }
        }
    }
</style>



<script>
import $ from 'jquery'

export default {
    mounted() {
        $(function(){ // DOCUMENT READY...
        /*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */(function(){



            



        })();/*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */(function(){






        })();/*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */});// DOCUMENT READY...
    }
}
</script>


